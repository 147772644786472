const policy = `
<h2>PRIVACY POLICY</h2> 

<p>Bluvec Technologies Inc. (the "Company “or “Bluvec”) is committed to protecting the Personal Information of our employees, website visitors, subscribers, clients, and other stakeholders (the "Users''). The Privacy Policy captures the types of Personal Information company collects, stores and the Company's privacy practices with respect to Personal Data collected.<br/><br/>

To the Company Privacy Policy, the commingling of data collected by Bluvec Technologies Inc. shall not be a disclosure of Personal Information third party-party. Your Personal Data will always be held in confidentiality and with a high level of security consistent with this Privacy Policy. IF YOU DO NOT CONSENT TO BLUVEC's TERM FOR PRIVACY POLICY, PLEASE REFRAIN FROM USING COMPANY WEBSITE AND/OR PRODUCTS and SERVICES. <br/><br/>

The purposes of this Privacy Policy are to: <br/>
1. Inform what Personal Data/Personal Information) Bluvec collects, stores, and uses <br/>
2. Inform the options you can make about how your information is, stored, organized, and used by the company <br/> 
3. Advice how company intends to use the collected Private Information <br/>
4. Implemented solutions that protect your Personal Information and maintain Privacy<br/><br/> 

This Policy establishes the Company's policies and procedures regarding all Personal Data collected, used, and disclosed by the Bluvec. This Privacy Policy applies to all employees of the Company, anyone that uses any of the company products, services provided by Bluvec Technologies Inc., and anyone that accesses any of the websites linked to this Privacy Policy and that are operated by Bluvec.<br/><br/>

The Company's websites may contain links to third-party websites, applications and/or services. The information practices or the content of such other websites are governed by the privacy statements and policies of those websites. <br/><br/>

If you have any questions, or require additional information, please contact us at hello@bluvec.com or by phone at +1 604-291-0073 <br/><br/></p>

<h2>ABOUT PERSONAL INFORMATION (PIPEDA) </h2>

<p>Personal Information refers to any information about an identified or identifiable individual or companies. The term "personal information" has the meaning as its set in the federal Personal Information Protection and Electronic Documents Act (PIPEDA), which means any information about an identifiable individual including contact information, name, address, phone number, email address, gender, and any other data about yourself that you choose to provide electronically through the Website or otherwise, i.e., through the Partner Registration form or Newsletter Subscription.<br/><br/>

We may use or disclose your collected personal information without your knowledge or further consent in limited circumstances, such as where we are required by law. In limited circumstances, we may be required to release personal information in response to a court order, law, or regulation.<br/><br/></p>

<h2>BLUVEC WILL OBTAIN YOUR CONSENT</h2>

<p>By using Bluvec's website or by otherwise providing personal information to the Company, you agree to this Privacy Policy and that we may collect, use and disclose your personal information in accordance with it. You may withdraw your consent at any time in writing. Please contact us at hello@bluvec.com or by phone +1- 604-291-0073 to learn how to withdraw your consent. <br/><br/>

We will seek your consent at the time we collect your personal information. Your consent may be implied. Implied consent can be reasonably inferred from your action (providing personal information by submitting data) or inaction. <br/><br/>

Personal data collected on this website may also be combined with information you provide us through other sources such as other Company websites, product inquiry submissions, chats, or in conjunction with events such as trade shows, training seminars and webinars. Information that you supply will relate to the relationship that the Company has with you or your organization. <br/><br/>

<h2>THE PERSONAL INFORMATION WE COLLECT</h2>

<p>The Company only collects the amount and type of Personal Information that is necessary for the purposes for which the Personal Information is collected. What follows is the type of Personal Information that we may collect, depending on your relationship with the Company and how you use our services.<br/><br/>

1. Personal Information of Employees <br/>
The following list includes, but is not limited to, the Personal Information that may be collected by the Company respecting employees: <br/>
I. contact information, including name, home address, telephone number, email address. criminal background check(s);<br/>
II. employment information, including resume and education background, emergency contacts. <br/>
III. financial information, including pay cheque deposit information and tax-related information, and Social Insurance Number or other required government-issued identification.<br/><br/>

2. Personal Information of All Website Users<br/>
The following list includes the type of Personal Information that may be collected from users that utilize services provided by the Company, which includes: <br/>
I. contact information, including name, company, industry, address, telephone number, email address.<br/>
II. usage data respecting use of the Company's website(s) through the website(s) (or through third-party services employed by the website(s)) which can include the IP addresses or domain names of the computers utilized by the Users; the URL addresses; the time of the request; the method utilized to submit the request to the server; the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the website(s)) and the details about the path followed within the website(s) with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment; and Unless specified otherwise, all Personal Information requested by the Company is mandatory for Bluvec to provide its products and services. <br/><br/>
 
Any questions respecting what information is mandatory can be directed at hello@bluvec.com or by phone +1-604 291 0073<br/><br/>

The Company may also collect Personal Information for the purpose of evaluating overall market trends and other activities relating to company business. To provide timely, valuable information, we may also ask you to provide us with information regarding your professional interests and experiences with our CUAS solutions or services (surveys and feedback). <br/><br/></p>

<h2>HOW BLUVEC COLLECTs YOUR PERSONAL INFORMATION?</h2>
<p>We collect information in the following ways: <br/>
1. Information you give us: Some of our services require you to sign up for an account. When you do, we will ask for Personal Information, including your name, birth date, email address, phone number and other applicable information to create your account. Some of our services will also require you to provide us with your Personal Information in order to obtain a product or receive information from us, such as newsletters or other email messages containing information of a commercial or promotional nature. <br/><br/>

2. Cookies and similar technologies: The Company use cookies or other tracking tools to provide services required by the User, if consented to by the User.<br/><br/>

3. Google Analytics: Google Analytics is a web analysis service provided by Google Inc. ("Google") that is used on the Company's website(s). Google utilizes the data collected to track and examine the use of the Company's website(s), to prepare reports on the Company's website(s) activities and to provide the Company with other services related to website and Internet use. Google may use the data collected to contextualize and personalize the advertisements of its own advertising network. <br/><br/>

Google Analytics processes information in the USA. The Personal Information collected by Google includes cookies and usage data. We encourage you to review the Privacy Policy for Google Analytics if you would like any further details regarding how they process Personal Information. </p>

<h2>ACCESS TO YOUR PERSONAL INFORMATION WITHIN BLUVEC? </h2>
Only those employees and vetted individuals of the Company who require access for business reasons and scope of providing service (shipping providers, logistic companies, payment processors) HOW DO WE USE THE PERSONAL INFORMATION?<br/><br/>
We collect your Personal Information to operate, maintain, enhance, and provide all features of the Company's services to send you marketing communications, to respond to comments and questions, to provide support to Users of the Company's services. We use information collected from cookies and other technologies to improve your experience and the overall quality of our services and website functionality. 

<h2>WHAT IS A COOKIE?</h2>
<p>Cookies are small amounts of text or software code, which is often a unique and anonymous identifier that is stored on your computer and only with your permission. Cookies help facilitate your experience on the Sites by updating things like your date and time of visit and general interaction on the Sites. </p>

<h2>HOW DO I CONSENT TO OR BLOCK COOKIES?</h2>
<p>When you access Bluvec Technologies Inc. website you will be asked to consent to Bluvec's use of the Cookies described in this Cookie Policy. <br/>
Should you not consent to Bluvec's use of Cookies you may find that the Sites functionality might be affected. Cookies that Bluvec applies to enhance user experience: <br/>__hs_cookie_cat_pref<br/> Hs-messages-is-open<br/><br/>

These cookies are used to distinguish users and expedite user experience on the website. If your Personal Information is to be utilized for any purpose besides that outlined at the time of collection, you will be notified prior to the Company using your Personal Information for that purpose. <br/><br/></p>

<h2>HOW IS YOUR PERSONAL INFORMATION SAFE?</h2>

<p>The Company is committed to protecting the confidentiality and security of all Personal Information against loss and unauthorized access, disclosure, modification, or destruction, and therefore has technological solutions appropriate to the sensitivity level of the information collected. The Company will ensure that all employees and third-party service providers with access to information of individuals shall be required as a condition of employment or provision of services to respect the confidentiality of such information and that all employees and third-party service providers are aware of the importance of maintaining the highest level of confidence. <br/><br/></p>

<h2>DISCLOSURE OF YOUR PERSONAL INFORMATION OUTSIDE OF THE COMPANY</h2>
<p>The Company may disclose your Personal Information with third party companies, organizations, and individuals outside of the Company if: You have provided your consent: We will share Personal Information with companies, organizations, or service providers outside of the Company when we have your consent. <br/><br/>

For external data processing: We may provide Personal Information to certain third-party agents or service providers to process for us, to carry out the requested services or as necessary for otherwise lawfully processing Personal Information. For example, Bluvec may share your data with payment processors so that these payment processors can provide services on our behalf.<br/><br/>

To comply with a legal obligation: The Company may disclose Personal Information if required to do so pursuant to any applicable law, regulation, legal process or enforceable governmental request. Additionally, under the Legislation and other applicable laws, the Company may be required to disclose some of a User's Personal Information to government officials, law enforcement personnel, or competent authorities of foreign governments. This information includes: an individual's given name, surname, contact information including the individual's mailing address, phone number, and email address. <br/><br/>

For purposes of Agreements/Contracts: Bluvec may disclose Personal Information for executing a contract to which a User is part or to take steps at the request of the User prior to entering a contract. The Company shall not otherwise disclose Personal Information to third parties for commercial or other reasons, except as may be specifically required to comply with applicable laws or where you have provided your agreement. <br/><br/></p>

<h2>HOW LONG IS YOUR PERSONAL DATA RETAINED?</h2> 
<p>Personal Information and Personal Data shall be stored for as long as required by the purpose for which it has been collected, used, or disclosed.<br/><br/>

Personal Information or Personal Data which the Company no longer needs to retain shall be destroyed or made anonymous in a secure manner in accordance with the Company's policies respecting the destruction of data and record keeping policy<br/><br/></p>

<h2>MARKETING COMMUNICATIONS</h2> 
<p>At any time, you can stop receiving marketing email communications from Bluvec by clicking on the “opt out" link provided in marketing communications. If you have any questions about reviewing, adjusting, or removing your information from our marketing communications, please contact us at hello@bluvec.com or by phone +1 604-291-0073<br/><br/></p> 

<h2>CHANGES TO THIS PRIVACY POLICY</h2> 
<p>If we make a change to this Privacy Policy, we will provide you with notice (for example, by email, a sign-in notification, or some other means) prior to the change becoming effective. Should the changes affect processing activities performed based on the User's consent, the Company shall collect new consent from the User, where required.<br/><br/></p>

<h2>FOR INQUIRIES</h2> 
<p>If you have questions about our Privacy Policy or have a concern or inquiries about terms, confidentiality, or our information handling practices; please contact us at hello@bluvec.com or by phone at +1 604-291-0073 for more information.</p>
`

export default policy;