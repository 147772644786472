function handleDownload(e) {
    if (localStorage.getItem("downloadable")) {
        return
    }
    e.preventDefault();
    const modal = document.getElementById("downloadModal");
    if (modal && modal.style) {
        modal.style.display = "block";
    }
}

export { handleDownload };