import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import articles from '../../data/articles';
import { injectIntl } from 'react-intl';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const PressBodySection = injectIntl(({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    intl,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    let articleContainer = {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        rowGap: "50px",
        columnGap: "80px"
    };

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <ul style={articleContainer} >
                        {
                            articles.map(article => {
                                return (
                                    article.title != props.title && (<li style={{ order: article.order }}>
                                        <div style={{ width: "350px", maxWidth: "90vw" }}>
                                            <Link to={`/Resources/Article/${article.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                                <img
                                                    src={`${article.coverImage}`}
                                                    style={{ marginBottom: 10, marginLeft: "auto", marginRight: "auto" }}
                                                />
                                            </Link>
                                            <a href={`/Resources/Article/${article.title.replace(/\s+/g, '-').toLowerCase()}`}>
                                                {article.title}
                                            </a>
                                            <div style={{ marginTop: 10 }}>{intl.formatMessage({ id: article.intro })}</div>
                                        </div>
                                    </li>)
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    );
})

PressBodySection.propTypes = propTypes;
PressBodySection.defaultProps = defaultProps;

export default PressBodySection;
