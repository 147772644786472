import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./saga";
import LocaleReducer from "./changeLocale/reducer";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const store = configureStore({
    reducer: {
        ChangeLocale: LocaleReducer,
    },
    middleware: [...middlewares],
});
sagaMiddleware.run(rootSaga);

export { store };