import React from 'react';
import { injectIntl } from 'react-intl';

const TransitionEffect = () => {
    return (
        <div className="common-wrapper" style={{ position: "relative" }}>
            <div class="ellipse-17"></div>
            <div class="ellipse-18"></div>
            <div class="ellipse-20"></div>
            <div class="ellipse-22"></div>
            <div class="ellipse-26"></div>
            <div class="ellipse-27"></div>
            <div class="ellipse-21"></div>
            <div class="ellipse-16"></div>
        </div>
    );
};

const SolutionBlock = injectIntl((props) => {
    const { intl, hideEffect } = props;
    return (
        <div style={{ position: "relative", alignSelf: "center" }}>
            {!hideEffect && <TransitionEffect />}
            <div className="solution-flex">
                {!hideEffect && <h1 className="solution-title mont-semi-bold-white-70px">{intl.formatMessage({ id: "solution.solution" })}</h1>}
                <a href='/Solutions/Airports' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_airports.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.airport" })}</h1>
                    </button>
                </a>
                <a href='/Solutions/Correctional' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_correctional.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.prison" })}</h1>
                    </button>
                </a>
                <a href='/Solutions/Infrastructure' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_critical.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.infra" })}</h1>
                    </button>
                </a>
                <a href='/Solutions/Public' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_public.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.public" })}</h1>
                    </button>
                </a>
                <a href='/Solutions/Military' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_military.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.military" })}</h1>
                    </button>
                </a>
                <a href='/Solutions/Commercial' style={{ zIndex: 99, height: 250 }}>
                    <button className="solution-item" style={{ backgroundImage: "url(/images/solution_commercial.png)" }}>
                        <h1 style={{ fontSize: 36 }}>{intl.formatMessage({ id: "header.solution.commercial" })}</h1>
                    </button>
                </a>
            </div>
        </div>
    );
});

export default SolutionBlock;