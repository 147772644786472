import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ReactHtmlParser from 'react-html-parser';
import { ImCheckboxChecked } from 'react-icons/im';
import HubspotContactForm from './Hubspot';
import { injectIntl } from 'react-intl';
import { scrollShow } from '../utils/RenderIfVisible';

const InfoCard = injectIntl((props) => {
    const [email, setEmail] = useState(null);
    const [name, setName] = useState(null);
    const [org, setOrg] = useState(null);
    const [phone, setPhone] = useState(null);
    const [country, setCountry] = useState("");
    const [industry, setIndustry] = useState("");
    const [interest, setInterest] = useState("");
    const [message, setMessage] = useState();
    const [errMessage, setErrMessage] = useState({});
    const { contact, intl } = props;

    const checkIsEmpty = (state, id) => {
        const input = document.getElementById(id);
        const msg = { ...errMessage };
        if (input && input.style) {
            if (state === "") {
                msg[id] = `Please input your ${id}`;
                input.style.border = "1px solid red";
            } else {
                input.style.border = "1px solid rgb(193, 229, 241)";
                msg[id] = "";
            };
            setErrMessage(msg);
        };
    };

    const inputOnChange = (setState, id, e) => {
        checkIsEmpty(e.target.value, id);
        setState(e.target.value);
    };

    return (
        <div className={'partner-card margin-center ' + (contact ? " partner-card2" : "")}>
            <div className="partner-card-key mont-semi-bold-white-25px">
                {intl.formatMessage({ id: "contact.fn" })}:
            </div>
            <input
                type="text" id="name" name="name"
                value={name} onChange={(e) => inputOnChange(setName, "name", e)}
                className="partner-form-input" style={{ marginRight: 80 }} />
            <div className="partner-card-key mont-semi-bold-white-25px" >
                {intl.formatMessage({ id: "contact.ln" })}:
            </div>
            <input
                type="text" id="org" name="org"
                value={org} onChange={(e) => inputOnChange(setOrg, "org", e)}
                className="partner-form-input" />
            <div className="partner-card-key mont-semi-bold-white-25px">
                {intl.formatMessage({ id: "footer.email" })}
                <span style={{ color: "red" }}>*</span>:
            </div >
            <input
                type="email" id="email" name="email"
                pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                value={email} onChange={(e) => inputOnChange(setEmail, "email", e)}
                className="partner-form-input" style={{ marginRight: 80 }} />
            <div className="partner-card-key mont-semi-bold-white-25px" >
                {intl.formatMessage({ id: "footer.phone" })}
                <span style={{ color: "red" }}>*</span>:
            </div >
            <input
                type="text" id="phone" name="phone"
                value={phone} onChange={(e) => inputOnChange(setPhone, "phone", e)}
                className="partner-form-input" />
            <div className="partner-card-key mont-semi-bold-white-25px" style={{ width: "35%" }}>
                {intl.formatMessage({ id: "contact.org" })}:
            </div >
            <input
                type="text" id="country" name="country" value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="partner-form-input" style={{ width: "60%" }} />
            <div className="partner-card-key mont-semi-bold-white-25px" style={{ width: "35%" }}>
                {intl.formatMessage({ id: "contact.cou" })}:
            </div >
            <input
                type="text" id="industry" name="industry" value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                className="partner-form-input" style={{ width: "60%" }} />
            <div className="partner-card-key mont-semi-bold-white-25px" style={{ width: "100%" }} >
                {intl.formatMessage({ id: "contact.interest" })}:
            </div >
            <input
                type="text" id="interest" name="interest" value={interest}
                onChange={(e) => setInterest(e.target.value)}
                className="partner-form-input" style={{ width: "96%" }} />
            <div className="partner-card-key mont-semi-bold-white-25px" style={{ width: "100%" }} >
                {intl.formatMessage({ id: "contact.msg" })}:
            </div >
            <textarea id="message" name="message" style={{ width: "96%" }} rows={10} value={message} onChange={(e) => setMessage(e.target.value)} />
            <div className='partner-form-checkbox'>
                <input type="checkbox" />
                <span className='mont-regular-normal-white-20px'>
                    {intl.formatMessage({ id: "contact.checkbox1" })}
                </span>
            </div>
            <div className='partner-form-checkbox'>
                <input type="checkbox" />
                <span className='mont-regular-normal-white-20px'>
                    {intl.formatMessage({ id: "contact.checkbox2" })}
                </span>
            </div>
            <button className="blusensor-learnMore">
                {intl.formatMessage({ id: "general.submit" })}
            </button>
        </div >
    );
});

const PartnerBlock = injectIntl((props) => {
    const { intl, title, content, imgSrc } = props;

    return (
        <div className='partner-intro fade-in-up-big common-wrapper'>
            <div class="mont-bold-white-48px">
                {ReactHtmlParser(intl.formatMessage({ id: title }))}
            </div>
            <img src="/images/technology_divider.png" style={{ marginTop: 30, marginBottom: 40, width: 500 }} />
            <div className="row-wrap" style={{ columnGap: 100, justifyContent: "space-between" }}>
                <p className="mont-regular-normal-white-20px" style={{ width: 700, lineHeight: 2 }} >
                    {ReactHtmlParser(intl.formatMessage({ id: content }))}
                </p>
                <img src={`/images/${imgSrc}`} style={{ height: 280 }} alt={`partner-${title}`} />
            </div>
        </div>
    );
});

const PartnerFeature = injectIntl((props) => {
    const { intl, featureNum, title, intlKey } = props;
    const feature = Array.from(Array(featureNum).keys());

    return (
        <div className='partner-intro fade-in' style={{ background: "var(--bgBlue)" }}>
            <div class="mont-bold-white-48px" style={{ textAlign: "center" }}>
                {ReactHtmlParser(intl.formatMessage({ id: title }))}
            </div>
            <div className="row-wrap bounce-in" style={{ columnGap: 50 }}>
                {
                    feature.map(index =>
                        <div className='par-fea-item mont-semi-bold-white-25px'>
                            <img
                                src={`/images/partner-${intlKey}-feature${index + 1}.png`}
                                style={{ height: 80, marginBottom: 30 }} className="margin-center"
                                alt={`partner-${intlKey}-feature${index + 1}`}
                            />
                            <p>{intl.formatMessage({ id: `partner.${intlKey}.feature${index + 1}` })}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
});

const PartnerOverview = injectIntl((props) => {
    const { intl } = props;
    const [showMap, setShowMap] = useState({
        "parFea1": false,
        "parFea2": false,
        "parFea3": false,
        "parForm": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Partner" option="partnerOverview" />
            <div className='partner-title fade-in'>
                <div className='mont-bold-white-80px'>{intl.formatMessage({ id: "partner.title1" })}</div>
                <div className="mont-regular-normal-white-30px" style={{ fontSize: 40 }}>{intl.formatMessage({ id: "partner.title2" })}</div>
            </div>
            {!showMap["parFea1"] && <div id="parFea1" className='decoy' style={{ top: 700 }} />}
            {showMap["parFea1"] && <PartnerBlock title="partner.title3" content="partner.content3" imgSrc="partner-1.png" />}
            {showMap["parFea1"] && <PartnerFeature title="partner.title4" featureNum={4} intlKey="cap" />}
            {!showMap["parFea2"] && <div id="parFea2" className='decoy' style={{ top: 700 }} />}
            {showMap["parFea2"] && <PartnerBlock title="partner.title5" content="partner.content5" imgSrc="partner-2.png" />}
            {showMap["parFea2"] && <PartnerFeature title="partner.title6" featureNum={4} intlKey="par" />}
            {!showMap["parFea3"] && <div id="parFea3" className='decoy' style={{ top: 700 }} />}
            {showMap["parFea3"] && <PartnerBlock title="partner.title7" content="partner.content7" imgSrc="partner-3.png" />}
            {showMap["parFea3"] && <PartnerFeature title="partner.title8" featureNum={4} intlKey="ref" />}
            {!showMap["parForm"] && <div id="parForm" className='decoy' style={{ top: 300 }} />}
            {showMap["parForm"] && <div className='partner-logo fade-in-up common-wrapper'>
                <div className="mont-bold-white-48px">{intl.formatMessage({ id: "partner.title9" })}</div>
                <img className='image-center' src="/images/technology_divider.png" style={{ marginTop: 20, marginBottom: 60, width: 420 }} />
                <div className='partner-card'>
                    <HubspotContactForm
                        region="na1"
                        portalId="22076592"
                        formId="3751f533-d3b6-4cad-b8a1-3ca98aaf84fc"
                        target="hubspotSubscribe2"
                    />
                </div>
            </div>}
            <Footer />
        </div >
    );
});

const PartnerLogin = () => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    return (
        <div className='siteContent'>
            <Header pageName="Partner" option="Login" />
            <div className='partner-login'>
                <div className='partner-login-card'>
                    <div className='mont-bold-white-80px'>
                        <div>Partner Login</div>
                        <img src="/images/technology_divider.png" style={{ marginTop: 10, marginBottom: 30, width: 600 }} />
                    </div>
                    <div className="partner-card-key mont-semi-bold-white-25px">Username:</div>
                    <input
                        type="text" id="username" name="username"
                        value={username} onChange={(e) => setUsername(e.target.value)}
                        className="partner-form-input" style={{ marginRight: 80 }} />
                    <div className="partner-card-key mont-semi-bold-white-25px" >Password:</div >
                    <input
                        type="text" id="password" name="password"
                        value={password} onChange={(e) => setPassword(e.target.value)}
                        className="partner-form-input" />
                    <div className='partner-form-checkbox' style={{ marginTop: 30, marginBottom: 20 }}>
                        <input type="checkbox" />
                        <span className='mont-regular-normal-white-20px'>Remember Me</span>
                    </div>
                    <button className='partner-form-btn mont-semi-bold-white-23px' style={{ marginLeft: 0 }}>Sign In</button>
                    <div className='mont-semi-bold-white-25px' style={{ width: "100%" }}>Don't have an account yet?</div>
                    <a className='mont-semi-bold-white-25px' style={{ width: "100%", color: "var(--denim)" }}>Register</a>
                    <div className='mont-semi-bold-white-25px' style={{ width: "100%" }}>Forgot your password?</div>
                    <a className='mont-semi-bold-white-25px' style={{ width: "100%", color: "var(--denim)" }}>Click here to reset</a>
                </div>
                <div className="partner-login-benefit mont-bold-white-30px" style={{ fontSize: 25 }}>
                    <div className="mont-bold-denim-14px" style={{ fontSize: 25 }}>By Login, you can get:</div>
                    <div style={{ display: "flex", columnGap: 20 }}>
                        <ImCheckboxChecked style={{ color: "lightgreen", fontSize: 25 }} />
                        <div>Products Price Lists</div>
                    </div>
                    <div style={{ display: "flex", columnGap: 20 }}>
                        <ImCheckboxChecked style={{ color: "lightgreen", fontSize: 25 }} />
                        <div>Marketing Materials</div>
                    </div>
                    <div style={{ display: "flex", columnGap: 20 }}>
                        <ImCheckboxChecked style={{ color: "lightgreen", fontSize: 25 }} />
                        <div>Tutorials</div>
                    </div>
                    <div style={{ display: "flex", columnGap: 20 }}>
                        <ImCheckboxChecked style={{ color: "lightgreen", fontSize: 25 }} />
                        <div>Training</div>
                    </div>
                    <img src="/images/partner-login.png" style={{ marginTop: -50, width: 600 }} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { PartnerLogin, PartnerOverview, InfoCard };