import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import RequestBlock from '../components/layout/Request';
import SolutionBlock from '../components/layout/Solution';
import ReactHtmlParser from 'react-html-parser';
import { injectIntl } from "react-intl";
import { productOverview, blusensorSpecs, blucamSpecs, blugunSpecs, blushieldSpecs, blucaseSpecs } from '../data/Miscellaneous';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { scrollShow } from '../utils/RenderIfVisible';
import { handleDownload } from '../utils/HubspotUtils';
import { DownloadModal } from './Resource';
import HubspotContactForm from './Hubspot';
import { isMobile } from '../utils/MobileAdapt';
import { BsFillArrowRightCircleFill, BsFillArrowDownCircleFill } from "react-icons/bs";

const BasicInfo = injectIntl((props) => {
    const { text1, text2, text3, bgSrc, bgHeight, imgSrc, imgWidth, intl, textWidth, sellStatusInfo, imgMarginLeft } = props;
    return (
        <div className='basicInfo fade-in-left'>
            <img className="basicInfo-background" style={{ height: bgHeight }} src={`/images/${bgSrc}`} />
            <div className='basicInfo-wrapper'>
                <div className='basicInfo-text' style={{ width: textWidth }}>
                    {sellStatusInfo == 2 && <img src="/images/new-icon.png" style={{ position: "relative", width: 80, top: 100, left: 400 }} />}
                    {text1 && <div className="basicInfo-text-1">{text1}</div>}
                    <div className="basicInfo-text-2">{intl.formatMessage({ id: text2 })}</div>
                    <img style={{ zIndex: 99, width: 600 }} src="/images/howitworks_divider.svg" />
                    <div className="basicInfo-text-3">{ReactHtmlParser(intl.formatMessage({ id: text3 }))}</div>
                </div>
                {imgSrc && <img className="basicInfo-image fade-in-right" style={{ width: imgWidth, marginLeft: imgMarginLeft || 0 }} src={`/images/${imgSrc}`} />}
            </div>
        </div>
    );
});

const DetailInfo = injectIntl((props) => {
    const { text1, text2, imgSrc, imgWidth, imgLeft, imgTop, colGap, downloadTop, intl, downloadLink, hasDisclaimer, detailImgMarginRight } = props;
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    return (
        <div className='detail fade-in-up-big' style={{ columnGap: colGap }}>
            <div className='detail-transition-1' />
            <div className='detail-transition-2' />
            {imgSrc && <img className="detail-image fade-in-left" style={{ width: imgWidth, marginLeft: imgLeft, marginTop: imgTop, marginRight: detailImgMarginRight || 0 }} src={imgSrc} />}
            <div className='detail-text' style={{ height: hasDisclaimer ? 950 : "auto" }}>
                <h1 className="mont-semi-bold-denim-36px">{intl.formatMessage({ id: text1 })}</h1>
                <div className="mont-regular-normal-white-18px">{ReactHtmlParser(intl.formatMessage({ id: text2 }))}</div>
                {hasDisclaimer && <div className="mont-regular-normal-red-18px">
                    <div
                        style={{ cursor: "pointer", display: "flex", columnGap: 5 }}
                        onClick={() => setShowDisclaimer(!showDisclaimer)}
                    >
                        {showDisclaimer ? <BsFillArrowDownCircleFill /> : <BsFillArrowRightCircleFill />}
                        Disclaimer
                    </div>
                    {showDisclaimer && <div>Blushield the "Devices" have not been authorized by the Radiocommunication
                        The Radiocommunication Act has not authorized Blugun (the "Device") (R.S.C., 1985, c. R-2) and related
                        Regulations in Canada and is not available to the general public. Bluvec Technologies may only sell or
                        lease the Devices in Canada to the RCMP (Royal Canadian Mounted Police), C.A.F., its agencies, and it's
                        adequately delegated representatives until such authorization is available.
                        For users outside Canada, Bluvec may outsource the manufacturing and customization of the Devices outside Canada,
                        where the Law permits and the user(s) possess the authorization to use the devices.
                        .</div>}
                </div>}
                <div className="download-wrapper"><a href={downloadLink} download onClick={handleDownload}>
                    <button className="detail-download mont-semi-bold-white-23px" style={{ top: downloadTop, fontSize: 18 }}>
                        {intl.formatMessage({ id: "general.download.datasheet" })}
                    </button></a>
                </div>
            </div>
        </div>
    );
});

const DetailInfo2 = injectIntl((props) => {
    const { intl, title, content } = props;

    return (
        <div className='detail fade-in' style={{ padding: "100px 50px" }}>
            <div className='detail-transition-1' />
            <div className='detail-transition-2' />
            <div className='detail-text margin-center' style={{ width: 1200 }}>
                <h3 style={{ color: "var(--denim)" }}>{intl.formatMessage({ id: title })}</h3>
                <p className="mont-regular-normal-white-18px">
                    {ReactHtmlParser(intl.formatMessage({ id: content }))}
                </p>
            </div>
        </div>
    );
});

const FeatureBlock = injectIntl((props) => {
    const { intl, bgSrc, intlKey, featureData, prefix } = props;
    const [show, setShow] = useState(null);

    return (
        <div className='featureBlock fade-in' style={{ backgroundImage: bgSrc ? `url(${bgSrc})` : "url(/images/feature_background.png)" }}>
            <div className="mont-semi-bold-white-70px" style={{ textAlign: "center" }} >{intl.formatMessage({ id: "product.features" })}</div>
            <div id="feature" style={{ position: "relative", top: 400 }} />
            <div className='feature-flex'>
                {
                    featureData.map((margin, index) =>
                        <div className='feature-item bounce-in' key={"feature" + index + 1}
                            onMouseOver={() => setShow(index)} onMouseOut={() => setShow(null)} style={{ marginTop: margin }}>
                            {show !== index && <img
                                className='feature-image'
                                alt={`${intlKey}-feature-${index + 1}`}
                                src={`/images/feature-${intlKey}${index + 1}.png`}
                            />}
                            {show !== index && <div className="mont-semi-bold-white-23px" style={{ textAlign: "center" }}>
                                {intl.formatMessage({ id: `${prefix}.${intlKey}.feature${index + 1}.title` })}
                            </div>}
                            {show === index &&
                                <div className='feature-item feature-hover' style={{ marginTop: -margin }}>
                                    <img
                                        className='feature-image fade-in'
                                        alt={`${intlKey}-feature-${index + 1}`}
                                        src={`/images/feature-${intlKey}${index + 1}.png`}
                                    />
                                    <div className="mont-semi-bold-white-23px fade-in" style={{ textAlign: "center", fontSize: 20 }}>
                                        {intl.formatMessage({ id: `${prefix}.${intlKey}.feature${index + 1}.title` })}
                                    </div>
                                    <div className="mont-regular-normal-white-18px fade-in feature-item-text">
                                        {intl.formatMessage({ id: `${prefix}.${intlKey}.feature${index + 1}.content` })}
                                    </div>
                                </div>}
                        </div>
                    )
                }
            </div>
        </div>
    );
});

const MethodBlock = injectIntl((props) => {
    const { youtubeEmbed, num, intlKey, intl } = props;
    const numArray = num ? Array.from(Array(num).keys()) : [];

    return (
        <div className='howItWorks fade-in-up-big'>
            <div className='howItWorks-background' />
            <div style={{ textAlign: "center", height: youtubeEmbed ? 800 : "auto" }}>
                <div className="mont-semi-bold-white-70px" style={{ zIndex: 999 }}>
                    {intl.formatMessage({ id: "method.title" })}
                </div>
                {youtubeEmbed}
            </div>
            {!youtubeEmbed && <div className='howItWorks-flex'>
                {
                    numArray.map(index =>
                        <div className='howItWorks-item' key={"method" + index + 1}>
                            <img src={`/images/${intlKey}-method-${index + 1}.png`} style={{ height: 100, borderRadius: 10 }} className="margin-center" />
                            <div style={{ marginTop: 30, marginBottom: 30 }} className="mont-semi-bold-white-45px">{index + 1}</div>
                            <div className="howItWorks-detail mont-regular-normal-white-25px" style={{ fontSize: 20 }}>
                                {intl.formatMessage({ id: `method.${intlKey}${index + 1}` })}
                            </div>
                            <img style={{ height: 10 }} src="/images/howitworks_divider.svg" />
                        </div>
                    )
                }
            </div>}
        </div>
    );
});

const ProductOverview = injectIntl((props) => {
    const { intl } = props;
    const [showMap, setShowMap] = useState({
        "proSen": false,
        "proCam": false,
        "proShi": false,
        "proGun": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className="siteContent">
            <Header pageName="Products" option="productOverview" />
            <div style={{ position: "relative", marginTop: "87px", width: "100%", height: isMobile() ? "10px" : "0px" }}>
                <div className="ellipse-14"></div>
                <div className="overview-title fade-in-up">{intl.formatMessage({ id: "product.overview.title" })}</div>
                {/* <img className="overview-image fade-in-up" src="/images/contact-company.png" /> */}
            </div>
            <div className='overview-flex'>
                {
                    productOverview.map((item, index) => item.name ?
                        <div className='overview-item' key={index.toString()}>
                            {!showMap[item.decoy] && <div className='decoy' id={item.decoy} style={{ width: 600 }} />}
                            {showMap[item.decoy] && <div className='fade-in'>
                                <div className="mont-semi-bold-denim-48px">{item.name}</div>
                                <div className="mont-regular-normal-white-25px overview-item-text">
                                    {intl.formatMessage({ id: item.msg })}
                                </div>
                                <div className="blusensor-learnMore-wrapper">
                                    <a href={item.href}>
                                        <button className="blusensor-learnMore">{intl.formatMessage({ id: "general.learnMore" })}</button>
                                    </a>
                                </div>
                            </div>}
                        </div>
                        :
                        <div>
                            {!showMap[item.decoy] && <div className='decoy' id={item.decoy} style={{ width: 400 }} />}
                            {showMap[item.decoy] && <div className='fade-in-up'>
                                <img alt={item.imgSrc} src={`/images/${item.imgSrc}`} width={item.width} style={{ marginRight: item.marginRight || 0 }} />
                            </div>}
                        </div>
                    )
                }
            </div>
            <SolutionBlock />
            <RequestBlock />
            <Footer />
        </div>
    );
});

const DownloadBtn = injectIntl((props) => {
    const { link, top } = props;
    const [dwlHover, setDwlHover] = useState(false);
    return (
        <a href={link} download onClick={handleDownload}>
            <button className='technical-download' style={{ marginTop: top }} onMouseOver={() => setDwlHover(true)} onMouseOut={() => setDwlHover(false)}>
                <div className='technical-download-image'>
                    <img className={'download-icon ' + (dwlHover ? "bounce-icon" : "")} width={40} src="/images/download_icon.svg" alt="download" />
                </div>
                <div className="technical-download-text mont-bold-white-20px">
                    <div className='absolute-center'>
                        <span style={{ letterSpacing: dwlHover ? 1.6 : 0 }}>DOWNLOAD</span><br />
                        <span style={{ letterSpacing: dwlHover ? 2.6 : 0.8 }}>DATASHEET</span>
                    </div>
                </div>
            </button>
        </a>
    );
});

const TechnicalSpec = (props) => {
    const { title, imgSrc, showOption, imgLeft, imgRight, link } = props;
    const [option, setOption] = useState("a1000");

    const changeRange = (option) => {
        setOption(option);
    };

    const SpecOption = () => {
        return (
            <div className='technical-option mont-semi-bold-white-25px'>
                <div
                    onClick={() => changeRange("a1000")}
                    className='technical-option-item'
                    style={{ borderColor: option === "a1000" ? "var(--white)" : "var(--denim)" }}>
                    B1000
                </div>
                <div
                    onClick={() => changeRange("a2000")}
                    className='technical-option-item'
                    style={{ borderColor: option === "a2000" ? "var(--white)" : "var(--denim)" }}>
                    B2000
                </div>
                <div
                    onClick={() => changeRange("a8000")}
                    className='technical-option-item'
                    style={{ borderColor: option === "a8000" ? "var(--white)" : "var(--denim)" }}>
                    B8000
                </div>
            </div>
        );
    };

    const SpecCard = injectIntl((props) => {
        const { height, text1, text2, intl, link, index, id, text2opt1, text2opt2, text2opt3, text2LV } = props;
        return (
            <div key={index.toString()}>
                <img src="/images/howitworks_divider.svg" />
                {text1 && <div className='technical-item' style={{ height: height }}>
                    <div className="technical-text-1">{intl.formatMessage({ id: text1 })}</div>
                    <div className="technical-text-2 mont-semi-bold-white-25px" id={id ? id : `spec-${index + 1}`}>
                        {
                            title === ("Blusensor LV " + intl.formatMessage({ id: "product.specs" })) ?
                                (text2LV ? ReactHtmlParser(intl.formatMessage({ id: text2LV })) : ReactHtmlParser(intl.formatMessage({ id: text2 })))
                                :
                                (
                                    (text2 && ReactHtmlParser(intl.formatMessage({ id: text2 }))) ||
                                    (text2opt1 && option === "a1000" && ReactHtmlParser(intl.formatMessage({ id: text2opt1 }))) ||
                                    (text2opt2 && option === "a2000" && ReactHtmlParser(intl.formatMessage({ id: text2opt2 }))) ||
                                    (text2opt3 && option === "a8000" && ReactHtmlParser(intl.formatMessage({ id: text2opt3 })))
                                )
                        }
                    </div>
                    {link &&
                        <a href={link}>
                            <button className='mont-regular-normal-white-18px blusensor-learnMore2'>
                                {intl.formatMessage({ id: "general.learnMore" })}
                                <FaRegArrowAltCircleRight />
                            </button>
                        </a>}
                </div>}
            </div>
        );
    });

    return (
        <div className='technical fade-in-up-big'>
            <div className='detail-transition-2' style={{ left: 500, top: 350 }} />
            <div className="technical-title mont-semi-bold-white-70px">{title}</div>
            {showOption && <SpecOption />}
            <div className="technical-flex">
                <div>
                    {
                        blusensorSpecs.map((item, index) => {
                            if (index % 2 === 0) {
                                return (
                                    <SpecCard
                                        text1={item.text1} text2={item.text2} height={item.height}
                                        link={item.link} index={index}
                                        text2opt1={item.text2opt1} text2opt2={item.text2opt2} text2opt3={item.text2opt3} text2LV={item.text2LV}
                                    />
                                );
                            };
                        })
                    }
                </div>
                <div className='technical-middle-wrapper' style={{ marginLeft: imgLeft, marginRight: imgRight }}>
                    <img className='technical-image' src={`/images/${imgSrc}`} />
                    <DownloadBtn link={link} />
                </div>
                <div>
                    {
                        blusensorSpecs.map((item, index) => {
                            if (index % 2 === 1) {
                                return (
                                    <SpecCard
                                        text1={item.text1} text2={item.text2} top={item.top} height={item.height}
                                        link={item.link} index={index}
                                        text2opt1={item.text2opt1} text2opt2={item.text2opt2} text2opt3={item.text2opt3} text2LV={item.text2LV}
                                    />
                                );
                            };
                        })
                    }
                </div>
            </div>
        </div>
    );
};

const TechnicalSpec2 = injectIntl((props) => {
    const { specData, imgLeft, imgBottom, imgWidth, imgSrc, intl } = props;
    const SpecCard = (props) => {
        const { height, text1, text2, link, index, borderRight } = props;
        return (
            <div className='technical-item-general' style={{ height: height, borderRight: borderRight ? 0 : "2px solid var(--denim)" }} key={index.toString()} >
                {text1 && <div className="technical-text-1">{intl.formatMessage({ id: text1 })}</div>}
                {text2 && <div className="technical-text-2 mont-semi-bold-white-25px">{ReactHtmlParser(intl.formatMessage({ id: text2 }))}</div>}
                {link &&
                    <a href={link}>
                        <button className='mont-regular-normal-white-18px blusensor-learnMore2'>
                            {intl.formatMessage({ id: "general.learnMore" })}
                            <FaRegArrowAltCircleRight />
                        </button>
                    </a>}
            </div>
        );
    };
    return (
        <div className='technical fade-in-up-big' style={{ marginBottom: 200 }}>
            <div className='technical-flex2'>
                <div className='detail-transition-2' style={{ left: 850, top: 50 }} />
                <div>
                    <div className="technical-title mont-semi-bold-white-70px" style={{ marginBottom: 100 }}>{ReactHtmlParser(intl.formatMessage({ id: "product.specs" }))}</div>
                    {
                        specData.slice(0, 2).map((item, index) =>
                            <div className='technical-item-general' style={{ width: 540, height: 150, borderRight: 0 }} key={index.toString()}>
                                <div className="technical-text-1">{intl.formatMessage({ id: item.text1 })}</div>
                                <div className="technical-text-2 mont-semi-bold-white-25px">{ReactHtmlParser(intl.formatMessage({ id: item.text2 }))}</div>
                                {item.link &&
                                    <a href={item.link}>
                                        <button className='mont-regular-normal-white-18px blusensor-learnMore2'>
                                            {intl.formatMessage({ id: "general.learnMore" })}
                                            <FaRegArrowAltCircleRight />
                                        </button>
                                    </a>}
                            </div>
                        )
                    }
                </div>
                <img src={`/images/${imgSrc}`} alt="productImg" width={imgWidth} style={{ marginLeft: imgLeft, marginBottom: imgBottom, zIndex: 999 }} />
            </div>
            <div className="technical-flex" style={{ marginTop: 0 }}>
                {
                    specData.slice(2).map((item, index) => item.text1 ?
                        <SpecCard text1={item.text1} text2={item.text2} height={item.height} link={item.link} index={index} borderRight={index % 3 === 2} />
                        :
                        <div className='technical-item-general' key={index.toString()}>
                            <DownloadBtn link={item.download} top={0} />
                        </div>
                    )
                }
            </div>
        </div>
    );
});

const ProductTemplate = (props) => {
    const { name, intlKey,
        basicImg, basicWidth, imgMarginLeft,
        detailImg, detailImgMarginRight, detailWidth, detailHeight, detailTop, detailLeft, downloadLink, colGap,
        featureData, techTitle, techImg, techShowOption, techLink,
        techWidth, techLeft, techRight, techBottom, prefix,
        techTitle2, techImg2, techLink2, specData, youtubeEmbed, methodNum, sellstatus, hasDisclaimer
    } = props;
    const [showMap, setShowMap] = useState({
        "proDetail": false,
        "proFea": false,
        "proMethod": false,
        "proSpec": false,
        "proSpec2": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className="siteContent">
            <DownloadModal />
            <Header pageName="Products" option={intlKey} />

            <BasicInfo
                text2={name}
                text3={`product.${intlKey}.intro2`}
                bgSrc="radar-background.png" imgSrc={basicImg} imgWidth={basicWidth} imgMarginLeft={imgMarginLeft}
                sellStatusInfo={sellstatus}
            />


            {!showMap["proDetail"] && <div className='decoy' id="proDetail" />}
            {showMap["proDetail"] && <DetailInfo
                text1={`product.${intlKey}.title`}
                text2={`product.${intlKey}.content`}
                imgSrc={detailImg} hasDisclaimer={hasDisclaimer}
                imgWidth={detailWidth} detailImgMarginRight={detailImgMarginRight} height={detailHeight} colGap={colGap}
                imgLeft={detailLeft} downloadTop={detailTop} downloadLink={downloadLink}
            />}

            {!showMap["proFea"] && <div className='decoy' id="proFea" />}
            {showMap["proFea"] && <FeatureBlock featureData={featureData} intlKey={intlKey} prefix={prefix} />}

            {!showMap["proMethod"] && <div className='decoy' id="proMethod" />}
            {showMap["proMethod"] && <MethodBlock youtubeEmbed={youtubeEmbed} num={methodNum} intlKey={intlKey} />}

            {!showMap["proSpec"] && <div className='decoy' id="proSpec" />}
            {intlKey === "blusensor" && showMap["proSpec"] && <TechnicalSpec title={techTitle} imgSrc={techImg} showOption={techShowOption} link={techLink} />}
            {intlKey === "blusensor" && !showMap["proSpec2"] && <div className='decoy' id="proSpec2" />}
            {intlKey === "blusensor" && showMap["proSpec2"] && <TechnicalSpec title={techTitle2} imgSrc={techImg2} imgRight={techRight} imgLeft={techLeft} link={techLink2} />}
            {intlKey !== "blusensor" && showMap["proSpec"] && <TechnicalSpec2 specData={specData} imgSrc={techImg} imgWidth={techWidth} imgLeft={techLeft} imgBottom={techBottom} />}
            <div style={{ marginTop: 200 }} />
            <SolutionBlock />
            <RequestBlock />
            <Footer />

        </div >
    );
};

const ProductBlusensor = injectIntl((props) => {
    const { intl } = props;

    return <ProductTemplate
        name="BLUSENSOR" intlKey="blusensor"
        basicImg="blusensor-1-blue.png"
        detailImg="/images/blusensor-2-blue.png" detailWidth={400} downloadLink="/Blusensor Series.pdf"
        featureData={[0, 0, 30, 0, 0, 0]} prefix="product"
        techTitle={"Blusensor " + intl.formatMessage({ id: "product.specs" })}
        techImg="blusensor-spec-blue.png"
        techLink="/Blusensor B1000_B2000_B8000.pdf" techLeft={-50} techRight={-50}
        techShowOption={true}
        techTitle2={"Blusensor LV " + intl.formatMessage({ id: "product.specs" })}
        techImg2="blusensor-lv.png"
        techLink2="/Blusensor LV.pdf"
        methodNum={4}
    />
});

const ProductBlugun = () => {
    const youtubeEmbed = <iframe width="1066" height="600" src="https://www.youtube.com/embed/zllTjM_8AMA"
        style={{ marginTop: 50, maxWidth: "90vw", height: isMobile() ? 400 : "600" }} className="absolute-center"
        title="Blugun Quick Start Guide" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>;

    return <ProductTemplate
        name="BLUGUN" intlKey="blugun"
        basicImg="blugun.png" basicWidth={500}
        detailImg="/images/blugun-2.png" detailWidth={isMobile() ? 250 : 350} detailLeft={isMobile() ? 50 : 100} detailTop={750} detailHeight={800} downloadLink="/Blugun.pdf" colGap={50}
        featureData={[0, 0, 0, 0, 0, 0]} prefix="product"
        youtubeEmbed={youtubeEmbed}
        specData={blugunSpecs}
        techImg="blugun-3.png" techWidth={450} techLeft={-50} techBottom={100}
    />
};

const ProductBlucam = () => {
    return <ProductTemplate
        name="BLUCAM" intlKey="blucam"
        basicImg="blucam-1.png" basicWidth={300}
        detailImg="/images/blucam.png" detailWidth={400} detailTop={400} downloadLink="/Blucam B100.pdf"
        featureData={[30, 0, 30, 30, 0]} prefix="product"
        specData={blucamSpecs}
        techImg="blucam-Left.png" techWidth={400} techLeft={0} techBottom={100}
        methodNum={4}
    />
};

const ProductBlushield = () => {
    return <ProductTemplate
        name="BLUSHIELD" intlKey="blushield"
        basicImg="blushield-ad8000-1.png" basicWidth={240}
        detailImg="/images/blushield-ad8000-2.png" detailWidth={350} detailLeft={100} detailTop={750} detailHeight={800} downloadLink="/Blushield A8000.pdf"
        hasDisclaimer
        featureData={[0, 0, 30, 0, 0]} prefix="product"
        specData={blushieldSpecs}
        techImg="blushield-ad8000-3.png" techWidth={360} techLeft={40}
        methodNum={5}
    />
};

const ProductBlucase = () => {
    return <ProductTemplate
        name="BLUCASE" intlKey="blucase"
        basicImg="BlucaseP5000-1.png" basicWidth={240} imgMarginLeft={85}
        detailImg="/images/BlucaseP5000-2.png" detailWidth={280} detailImgMarginRight={80} detailLeft={100} detailTop={750} detailHeight={800} downloadLink="/Blucase P5000.pdf"
        featureData={[0, 0, 30, 0, 0, 0]} prefix="product"
        specData={blucaseSpecs}
        techImg="BlucaseP5000-3.png" techWidth={320} techLeft={-30}
        methodNum={4}
        sellstatus={2}

    />
}

const ProductSoftware = injectIntl((props) => {
    const { intl } = props;
    const planArray = Array.from(Array(4).keys());

    const [showForm, setShowForm] = useState(false);
    const [showMap, setShowMap] = useState({
        "softDetail1": false,
        "softDetail2": false,
        "softFea": false,
        "softIntro1": false,
        "softIntro2": false,
        "softCloud": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className="siteContent">
            <Header pageName="Products" option="software" />
            <BasicInfo
                text2="header.product.software"
                text3="product.software.intro"
                bgSrc="software-bg.png"
            />
            <div style={{ height: 100 }} />
            {!showMap["softDetail1"] && <div className='decoy' id="softDetail1" />}
            {showMap["softDetail1"] && <DetailInfo2 title="product.software.title" content="product.software.content" />}

            {!showMap["softDetail2"] && <div className='decoy' id="softDetail2" />}
            {showMap["softDetail2"] && <DetailInfo2 title="product.software.title2" content="product.software.content2" />}

            {!showMap["softFea"] && <div className='decoy' id="softFea" />}
            {showMap["softFea"] && <FeatureBlock featureData={[0, 0, 0, 0, 0]} intlKey="software" prefix="product" />}

            {!showMap["softIntro1"] && <div className='decoy' id="softIntro1" />}
            {showMap["softIntro1"] && <div className='row-wrap software-illustrate fade-in-up-big' style={{ backgroundImage: "url(/images/software-bg1.png)", padding: "40px 20px", columnGap: 40 }}>
                <div className='partner-card tech-detail-text' style={{ width: 800 }}>
                    <h3>{ReactHtmlParser(intl.formatMessage({ id: "product.software.h1" }))}</h3>
                    <p className='inter-normal-white-20px'>
                        {ReactHtmlParser(intl.formatMessage({ id: "product.software.p1" }))}
                    </p>
                    <div style={{ height: 100, paddingTop: 20 }}>
                        <button className="blusensor-learnMore" onClick={() => setShowForm(true)}>
                            {intl.formatMessage({ id: "header.requestDemo" })}
                        </button>
                    </div>
                </div>
                <div>
                    <img src="/images/software-illustrate-1.png" width={500} />
                    <br />
                    <img src="/images/software-illustrate-2.png" width={500} />
                </div>
            </div>}

            {!showMap["softIntro2"] && <div className='decoy' id="softIntro2" />}
            {showMap["softIntro2"] && <div className='row-wrap software-illustrate fade-in-up-big' style={{ backgroundImage: "url(/images/software-bg2.png)", padding: "40px 20px", columnGap: 40 }}>
                <div>
                    <img src="/images/software-illustrate-3.png" width={700} />
                </div>
                <div className='partner-card tech-detail-text' style={{ width: 600 }}>
                    <h3>{ReactHtmlParser(intl.formatMessage({ id: "product.software.h2" }))}</h3>
                    <p className='inter-normal-white-20px'>
                        {ReactHtmlParser(intl.formatMessage({ id: "product.software.p2" }))}
                    </p>
                    <div style={{ height: 100, paddingTop: 20 }}><a href="https://cloud.bluvec.com">
                        <button className="blusensor-learnMore">
                            {intl.formatMessage({ id: "product.software.cloud" })}
                        </button>
                    </a></div>
                </div>
            </div>}

            {!showMap["softCloud"] && <div className='decoy' id="softCloud" />}
            {showMap["softCloud"] && <div className='fade-in-up-big'>
                <div className="mont-semi-bold-denim-48px" style={{ marginBottom: 50, marginTop: 100, textAlign: "center" }}>
                    {intl.formatMessage({ id: "product.software.plan" })}
                </div>
                <div className='row-wrap' style={{ gap: "20px 20px", marginBottom: 150 }}>
                    {
                        planArray.map(index =>
                            <div className='cloud-plan-item' key={"plan" + index + 1}>
                                <img src={`/images/cloud-plan-${index + 1}.png`} width={350} className="margin-center" />
                            </div>
                        )
                    }
                </div>
            </div>}

            <SolutionBlock />
            <RequestBlock />
            <Footer />
            {showForm && <div id="myModal" className="my-modal">
                <div className="my-modal-content fade-in-up">
                    <div className='close' onClick={() => setShowForm(false)}>&times;</div>
                    <p style={{ marginTop: 50, lineHeight: 1.4 }}>{intl.formatMessage({ id: "request.hint" })}</p>
                    <HubspotContactForm
                        region="na1"
                        portalId="22076592"
                        formId="fc7eef4b-5b26-4862-8fb5-b847ba6b72f7"
                        target="hubspotConsult"
                    />
                </div>
            </div>}
        </div>
    );
});

export { ProductOverview, ProductBlusensor, ProductBlucam, ProductSoftware, BasicInfo, FeatureBlock, ProductBlucase, ProductBlushield };
