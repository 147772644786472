import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { injectIntl } from 'react-intl';
import SolutionBlock from '../components/layout/Solution';
import RequestBlock from '../components/layout/Request';
import { homeFeature, hardF4, softF4 } from '../data/Miscellaneous';
import websiteHomeVideo from "../assets/images/website_home.mp4";
import { scrollShow } from '../utils/RenderIfVisible';
import ReactHtmlParser from "react-html-parser";
import { isMobile } from '../utils/MobileAdapt';

const SlideShow = injectIntl((props) => {
  const { intl } = props;
  const [show, setShow] = useState(0);
  const [myInterval, setMyInterval] = useState(null);
  const slideLen = 2;

  useEffect(() => {
    createInterval();
  }, []);

  const createInterval = () => {
    let slide = setInterval(() => {
      setShow(show => (show + 1) % slideLen);
    }, 8000);
    setMyInterval(slide);
  };

  const handleManual = () => {
    clearInterval(myInterval);
    createInterval();
  };

  return (
    <div>
      <div className="slideshow-container">
        <div style={{ position: "relative" }}>
          <video className='slide-video'
            autoPlay={true}
            controls={false}
            loop={true}
            playsInline
            muted>
            <source src={websiteHomeVideo} type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  );
});

const FantasyFour = injectIntl((props) => {
  const { intl, data, fit } = props;

  return (
    <div className='home-four-flex'>
      {
        data.map(item =>
          <a href={item.link}>
            <div className='home-four-item' key={item.content}>
              <img
                src={item.imgSrc}
                style={{
                  height: fit ? "auto" : 140,
                  marginLeft: item.left ? item.left : "auto",
                  marginRight: "auto",
                  marginBottom: fit ? 0 : 10,
                  marginTop: fit ? 0 : 10
                }}
              />
              <div className='mont-bold-white-30px' style={{ marginTop: fit ? 30 : 0 }}>
                {intl.formatMessage({ id: item.content })}
              </div>
            </div>
          </a>
        )
      }
    </div>
  );
});

const Home = injectIntl((props) => {
  const { intl } = props;
  const [showMap, setShowMap] = useState({
    "homeIntro": false,
    "homeFea": false,
    "homeHard": false,
    "homeSoft": false,
    "homeSol": false,
  });
  const [showNews, setShowNews] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
  }, []);

  return (
    <div className='siteContent'>
      <Header pageName="Home" />
      <SlideShow />

      {!showMap["homeIntro"] && <div className='decoy' id="homeIntro" />}
      {showMap["homeIntro"] && <div className='home-intro fade-in-up'>
        <div className='home-intro-text'>
          <h1 className='mont-bold-white-38px' style={{ color: "var(--pizazz)" }}>
            {intl.formatMessage({ id: "home.intro1" })}
          </h1>
          <h1 className='mont-regular-normal-white-30px'>
            {intl.formatMessage({ id: "home.intro2" })}
          </h1>
        </div>
      </div>}

      {!showMap["homeFea"] && <div className='decoy' id="homeFea" />}
      {showMap["homeFea"] && <div className='home-why margin-center fade-in-up'>
        <p className='mont-bold-white-48px'
          style={{
            marginLeft: isMobile() ? 0 : 200,
            marginTop: 30, marginBottom: 80,
            textAlign: isMobile() ? "center" : "left",
            fontSize: isMobile() ? 30 : 56,
          }}>
          {intl.formatMessage({ id: "home.why" })}
        </p>
        <div className='home-why-flex bounce-in'>
          {
            homeFeature.map((item, index) =>
              <div className='home-why-item' key={index}>
                <img src={item.imgSrc} style={{ height: 80 }} />
                <h1 className='mont-semi-bold-white-25px' style={{ marginTop: 20, marginBottom: 20 }}>
                  {intl.formatMessage({ id: item.title })}
                </h1>
                <div className='mont-regular-normal-white-20px'>
                  {intl.formatMessage({ id: item.content })}
                </div>
              </div>
            )
          }
        </div>
      </div>}

      <div className='home-f4 margin-center'>
        <div className='home-why-flex' style={{ alignItems: "center" }}>
          {!showMap["homeHard"] && <div className='decoy' id="homeHard" />}
          {showMap["homeHard"] && <a href="/Products/Overview">
            <div className='home-why-item fade-in-up-big'>
              <h1 className='mont-bold-white-48px'>{intl.formatMessage({ id: "home.our.hard" })}</h1>
              <p className='mont-regular-normal-white-20px'>{intl.formatMessage({ id: "home.our.hard.content" })}</p>
            </div>
          </a>}
          {showMap["homeHard"] && <div className='bounce-in-right'><img src="/images/new-tag.png" style={{ position: "relative", width: 112, top: 394, left: 67 }} /><FantasyFour data={hardF4} /></div>}

          {!showMap["homeSoft"] && <div className='decoy' id="homeSoft" style={{ width: "100%" }} />}
          {showMap["homeSoft"] && !isMobile() && <div className='bounce-in-left'><FantasyFour data={softF4} fit /></div>}
          {showMap["homeSoft"] && <a href="/Technology/Overview">
            <div className='home-why-item fade-in-up-big'>
              <h1 className='mont-bold-white-48px'>{intl.formatMessage({ id: "home.our.soft" })}</h1>
              <p className='mont-regular-normal-white-20px'>{intl.formatMessage({ id: "home.our.soft.content" })}</p>
            </div>
          </a>}
          {showMap["homeSoft"] && isMobile() && <div className='bounce-in-left'><FantasyFour data={softF4} fit /></div>}
        </div>
      </div>
      {!showMap["homeSol"] && <div className='decoy' id="homeSol" />}
      {showMap["homeSol"] && <div className='fade-in margin-center' style={{ maxWidth: "var(--max-width)" }}>
        <h1 className='home-solution mont-semi-bold-white-45px'>
          {intl.formatMessage({ id: "home.solution" })}
        </h1>
        <SolutionBlock hideEffect={true} />
      </div>}
      <RequestBlock />
      <Footer />
    </div>
  );
});

export default Home;
