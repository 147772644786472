import langList from './langList.json';

const AppLocale = {};

langList.forEach(language => {
  const antdLang = require(`antd/lib/locale-provider/${language.key}`);
  const messages = require(`./${language.key}.json`);
  const langData = {
    messages: { ...messages },
    antd: antdLang,
    locale: language.lang,
    popTitle: language.popTitle,
  };
  AppLocale[`${language.key}`] = langData;
});

export default AppLocale;
