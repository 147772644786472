import React from "react"
import { Redirect } from "react-router-dom";

const docList = ["BLUCAM A-100",
    "BLUGUN",
    "BLUSENSOR A-1000",
    "BLUSENSOR A-2000",
    "BLUSENSOR A-8000",
    "BLUSENSOR LV",
    "BLUSHIELD A-8000",
    "Bluvec_Catalogue_English.pdf",
    "Bluvec Technologies Inc spanish.pdf",
];

export function DocCreator(props) {
    const { params: { docName } } = props.match;
    const currentDoc = [];
    docList.forEach((doc, _) => {
        var document = doc.replace(/\s+/g, '');
        document = document.replace(/-+/g, '').toLowerCase();
        if (document === docName) {
            currentDoc.push(doc);
        }
    })
    console.log(currentDoc);
    return (
        <Redirect to={`/${currentDoc[0]}.pdf`} />
    )
}

export function LoadFile(props) {
    const { params: { fileName } } = props.match;
    if (fileName.slice(-3) === "pdf") {
        window.location.reload();
    }
    return
}