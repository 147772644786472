import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import HubspotContactForm from '../../views/Hubspot';
import localeActions from '../../redux/changeLocale/actions';
import langList from '../../languageProvider/langList.json';
import { isMobile } from '../../utils/MobileAdapt';
import { GiHamburgerMenu } from "react-icons/gi";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";

const { changeLocale } = localeActions;

const Header = (props) => {
  const { currentAppLocale, changeLocale, intl } = props;
  const [lang, setLang] = useState(false);
  const [option, setOption] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showBurger, setShowBurger] = useState(false);
  const [burgerOpt, setBurgerOpt] = useState("");

  const getFontColor = (page) => {
    return page === props.pageName ? "mont-bold-denim-14px" : "mont-bold-white-14px";
  }

  const getProductOption = (option) => {
    return option === props.option ? "product-option-yes" : "product-option-no";
  }

  const animation = "fade-in-down2";
  const ProductOption = () => {
    return (
      <div id="productOption" className={"product-option mont-bold-white-14px " + animation} style={{ top: 25, left: -10 }} >
        <a href='/Products/Overview' className={getProductOption("productOverview")}>{intl.formatMessage({ id: "header.overview" })}</a>
        <a href='/Products/Blusensor' className={getProductOption("blusensor")}>Blusensor</a>
        <a href='/Products/Blucam' className={getProductOption("blucam")}>Blucam</a>
        <a href='/Products/Blushield' className={getProductOption("blushield")}>Blushield</a>
        {/* <a href='/Products/Blugun' className={getProductOption("blugun")}>Blugun</a> */}
        <a href='/Products/Blucase' className={getProductOption("blucase")}>Blucase<font color="red"> NEW!</font></a>
        <a href='/Products/Software' className={getProductOption("software")}>{intl.formatMessage({ id: "header.product.software" })}</a>
      </div>
    );
  }

  const SolutionOption = () => {
    return (
      <div id="solutionOption" className={"product-option mont-bold-white-14px " + animation} style={{ top: 25, left: -10, width: 210 }} >
        <a href='/Solutions/Overview' className={getProductOption("solutionOverview")}>{intl.formatMessage({ id: "header.overview" })}</a>
        <a href='/Solutions/Airports' className={getProductOption("airports")}>{intl.formatMessage({ id: "header.solution.airport" })}</a>
        <a href='/Solutions/Correctional' className={getProductOption("correctional")}>{intl.formatMessage({ id: "header.solution.prison" })}</a>
        <a href='/Solutions/Infrastructure' className={getProductOption("infrastructure")}>{intl.formatMessage({ id: "header.solution.infra" })}</a>
        <a href='/Solutions/Public' className={getProductOption("public")}>{intl.formatMessage({ id: "header.solution.public" })}</a>
        <a href='/Solutions/Military' className={getProductOption("military")}>{intl.formatMessage({ id: "header.solution.military" })}</a>
        <a href='/Solutions/Commercial' className={getProductOption("commercial")}>{intl.formatMessage({ id: "header.solution.commercial" })}</a>
      </div>
    );
  }

  const TechnologyOption = () => {
    return (
      <div id="technologyOption" className={"product-option mont-bold-white-14px " + animation} style={{ top: 25, left: -10, width: 100 }}>
        <a href='/Technology/Overview' className={getProductOption("technologyOverview")}>{intl.formatMessage({ id: "header.overview" })}</a>
        <a href='/Technology/DSI' className={getProductOption("DSI")}>{intl.formatMessage({ id: "header.technology.DSI" })}</a>
        <a href='/Technology/TDOA' className={getProductOption("TDOA")}>TDOA</a>
        <a href='/Technology/RTI' className={getProductOption("RTI")}>{intl.formatMessage({ id: "header.technology.RTI" })}</a>
      </div>
    );
  }

  const AboutOption = () => {
    return (
      <div id="aboutOption" className={"product-option mont-bold-white-14px " + animation} style={{ top: 25, left: -10, width: 150 }}>
        <a href='/About-us/company' className={getProductOption("company")}>{intl.formatMessage({ id: "header.about.company" })}</a>
        <a href='/About-us/career' className={getProductOption("career")}>{intl.formatMessage({ id: "header.about.career" })}</a>
      </div>
    );
  }

  const LanguageOption = () => {
    return (
      <div id="languageOption" className="product-option mont-bold-white-14px" style={{ top: 25, left: -10, width: 100, display: "flex" }}>
        {
          langList.map((item, index) =>
            <a className={item.lang === currentAppLocale.locale ? "product-option-yes" : "product-option-no"}
              onClick={() => changeLocale(item.key)} key={index}>
              {item.popTitle}
            </a>
          )
        }
      </div >
    );
  }

  const OptionBlock = (props) => {
    const { optName, msgID, href, colorName, DropDownBox } = props;
    return (
      <div className='header-option-item' onMouseOver={() => setOption(optName)} onMouseOut={() => setOption("")}>
        <a href={href} className={getFontColor(colorName)} style={{ paddingTop: 15 }} >
          {intl.formatMessage({ id: msgID })}
          <div style={{ width: "100%", height: 30, top: 15, position: "absolute" }} />
        </a>
        {option === optName && <div className='header-underline header-bounce-in' />}
        {DropDownBox && option === optName && <DropDownBox />}
      </div>
    );
  }

  const HeaderOption = (props) => {
    if (isMobile()) {
      return (
        <div>
          <GiHamburgerMenu style={{ width: 30, cursor: "pointer" }} onClick={() => setShowBurger(!showBurger)} />
          {showBurger && <ul className='burger-header-dropdown mont-bold-white-14px'>
            <li className='burger-option-item' onClick={() => setBurgerOpt(burgerOpt === "productOption" ? "" : "productOption")}>
              Products {burgerOpt === "productOption" ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </li>
            {burgerOpt === "productOption" && <ul className='burger-header-subopt fade-in'>
              <li><a href='/Products/Overview'>Overview</a></li>
              <li><a href='/Products/Blucase'>Blucase</a></li>
              <li><a href='/Products/Blusensor'>Blusensor</a></li>
              <li><a href='/Products/Blucam'>Blucam</a></li>
              <li><a href='/Products/Blushield'>Blushield</a></li>
              {/* <li><a href='/Products/Blugun'>Blugun</a></li> */}
            </ul>}
            <li className='burger-option-item' onClick={() => setBurgerOpt(burgerOpt === "technologyOption" ? "" : "technologyOption")}>
              Technology {burgerOpt === "technologyOption" ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </li>
            {burgerOpt === "technologyOption" && <ul className='burger-header-subopt fade-in'>
              <li><a href='/Technology/Overview'>Overview</a></li>
              <li><a href='/Technology/DSI'>DSI</a></li>
              <li><a href='/Technology/RTI'>RTI</a></li>
              <li><a href='/Technology/TDOA'>TDOA</a></li>
            </ul>}
            <li className='burger-option-item' onClick={() => setBurgerOpt(burgerOpt === "solutionOption" ? "" : "solutionOption")}>
              Solutions {burgerOpt === "solutionOption" ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </li>
            {burgerOpt === "solutionOption" && <ul className='burger-header-subopt fade-in'>
              <li><a href='/Solutions/Overview'>Overview</a></li>
              <li><a href='/Solutions/Airports'>Airports</a></li>
              <li><a href='/Solutions/Correctional'>Correctional Institution</a></li>
              <li><a href='/Solutions/Infrastructure'>Critical Infrastructure</a></li>
              <li><a href='/Solutions/Military'>Milirary Settings</a></li>
              <li><a href='/Solutions/Commercial'>Commercial Settings</a></li>
            </ul>}
            <li className='burger-option-item'><a href='/Resources'>Resources</a></li>
            <li className='burger-option-item'><a href='/Partner'>Partner</a></li>
            <li className='burger-option-item' onClick={() => setBurgerOpt(burgerOpt === "aboutOption" ? "" : "aboutOption")}>
              About us {burgerOpt === "aboutOption" ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </li>
            {burgerOpt === "aboutOption" && <ul className='burger-header-subopt fade-in'>
              <li><a href='/About-us/company'>Our Company</a></li>
              <li><a href='/About-us/career'>Careers</a></li>
            </ul>}
            <li className='burger-option-item'><a href='/Contact'>Contact</a></li>
          </ul>}
        </div>
      );
    }

    return (
      <div className="header-option" id="headerOption" >
        <OptionBlock optName="home" colorName="Home" href="/" msgID="header.home" />
        <OptionBlock optName="productOption" colorName="Products" href="/Products/Overview" msgID="header.product" DropDownBox={ProductOption} />
        <OptionBlock optName="technologyOption" colorName="Technology" href="/Technology/Overview" msgID="header.technology" DropDownBox={TechnologyOption} />
        <OptionBlock optName="solutionOption" colorName="Solutions" href="/Solutions/Overview" msgID="header.solution" DropDownBox={SolutionOption} />
        <OptionBlock optName="resource" colorName="Resources" href="/Resources" msgID="header.resource" />
        <OptionBlock optName="partnerOption" colorName="Partner" href="/Partner" msgID="header.partner" />
        <OptionBlock optName="aboutOption" colorName="About-us" href="/About-us/Company" msgID="header.about" DropDownBox={AboutOption} />
        <OptionBlock optName="contact" colorName="Contact" href="/Contact" msgID="header.contact" />
        <button onMouseOver={() => setOption(null)} onClick={() => setShowForm(true)} className="request-button">
          {intl.formatMessage({ id: "header.requestDemo" })}
        </button>
        <div onMouseOver={() => setOption(null)} onClick={() => setLang(!lang)} className='header-option-item' style={{ cursor: "pointer" }}>
          <div className="mont-bold-white-14px" style={{ display: "inline-block" }}>{currentAppLocale.popTitle}</div>
          <img src="/images/polygon-13.svg" className='language-switch' alt="langSwitch" />
          {lang && <LanguageOption />}
        </div>
        {showForm && <div id="myModal" className="my-modal">
          <div className="my-modal-content fade-in-up">
            <div className='close' onClick={() => setShowForm(false)}>&times;</div>
            <p style={{ marginTop: 50, lineHeight: 1.4 }}>{intl.formatMessage({ id: "request.hint" })}</p>
            <HubspotContactForm
              region="na1"
              portalId="22076592"
              formId="fc7eef4b-5b26-4862-8fb5-b847ba6b72f7"
              target="hubspotConsult"
            />
          </div>
        </div>}
      </div>
    );
  }

  return (
    <div className="header" onMouseLeave={() => setOption(null)}>
      <a href='/'>
        <img className="primary-white-logo" src="/images/primary-white-logo-new.png" onMouseOver={() => setOption(null)} />
      </a>
      <HeaderOption />
    </div>
  );
}

export default injectIntl(
  connect(
    state => ({
      currentAppLocale: state.ChangeLocale.get('currentAppLocale'),
    }),
    { changeLocale }
  )(Header)
);