const droneNews = [
    {
        title: "1Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "2Drone War inside Irish prisons",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "3Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "4Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "5Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "6Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "7Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "8Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "9Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "10Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "11Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "12Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "13Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "14Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "15Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "16Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "17Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "18Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "19Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "20Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "21Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "22Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "23Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "24Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
    {
        title: "Drone War inside Irish prisons’ battle of the skies to stop criminals using drones for smuggling drugs",
        industry: "Correctional Institution",
        region: "Irish, Europe",
        date: "Sept 21, 2022",
        link: "http://google.com",
    },
];

export default droneNews;