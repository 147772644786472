import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import HubspotContactForm from '../../views/Hubspot';

const RequestBlock = injectIntl((props) => {
    const { intl } = props;
    const [showForm, setShowForm] = useState(false);

    return (
        <div className="request-block">
            {showForm && <div id="myModal" className="my-modal">
                <div className="my-modal-content fade-in-up">
                    <div className='close' onClick={() => setShowForm(false)}>&times;</div>
                    <p style={{ marginTop: 50, lineHeight: 1.4 }}>{intl.formatMessage({ id: "request.hint" })}</p>
                    <HubspotContactForm
                        region="na1"
                        portalId="22076592"
                        formId="fc7eef4b-5b26-4862-8fb5-b847ba6b72f7"
                        target="hubspotConsult"
                    />
                </div>
            </div>}
            <h1 className="request-title">{intl.formatMessage({ id: "request.title" })}</h1>
            <div className="request-content">{intl.formatMessage({ id: "request.content" })}</div>
            <button className="request-consultation-button mont-semi-bold-white-23px" onClick={() => setShowForm(true)}>
                {intl.formatMessage({ id: "request.button" })}
            </button>
        </div>
    );
});

export default RequestBlock;