import React, { useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ReactHtmlParser from 'react-html-parser';
import { injectIntl } from 'react-intl';
import { scrollShow } from '../utils/RenderIfVisible';
import HubspotContactForm from './Hubspot';

const Contact = injectIntl((props) => {
    const { intl } = props;

    const [showMap, setShowMap] = useState({
        "contact": false,
        "address": false,
    });

    useEffect(() => {
        window.addEventListener('scroll', () => scrollShow(showMap, setShowMap));
    }, []);

    return (
        <div className='siteContent'>
            <Header pageName="Contact" />
            <div className='about-company-title mont-bold-white-80px fade-in' style={{ backgroundImage: "url(/images/contact-bg.png" }}>
                {intl.formatMessage({ id: "contact" })}
            </div>
            <div className='about-company-content common-wrapper' style={{ padding: "100px 0px" }}>
                {!showMap["contact"] && <div className='decoy' id="contact" />}
                {showMap["contact"] && <div className='fade-in-up-big'>
                    <div className="mont-bold-denim-14px" style={{ fontSize: 38 }}>
                        {intl.formatMessage({ id: "contact.title" })}
                    </div>
                    <div className="mont-bold-white-30px" style={{ marginBottom: 30 }}>
                        {intl.formatMessage({ id: "contact.intro" })}
                    </div>
                    <div className='partner-card margin-center' style={{ maxWidth: "90vw", width: 1000, padding: "100px" }}>
                        <HubspotContactForm
                            region="na1"
                            portalId="22076592"
                            formId="ee1cf894-78bc-448e-9b32-c47689cb1815"
                            target="contact-us-form"
                        />
                    </div>
                </div>}

                {!showMap["address"] && <div className='decoy' id="address" />}
                {showMap["address"] && <div className='fade-in-up-big'>
                    <div className="mont-bold-denim-14px" style={{ marginTop: 50, marginBottom: 30, fontSize: 38 }}>
                        {intl.formatMessage({ id: "contact.office" })}
                    </div>
                    <div className='contact-flex'>
                        <img src="/images/contact-company.png" width={550} />
                        <div className='mont-bold-white-30px' style={{ textAlign: "left", maxWidth: "90vw" }}>
                            Bluvec Technologies Inc. <br /><br />
                            <div className='contact-info'>
                                <div style={{ width: 280 }}>
                                    <div>{intl.formatMessage({ id: "footer.phone" })}:</div>
                                    <div>{intl.formatMessage({ id: "footer.email" })}:</div>
                                    <div>{intl.formatMessage({ id: "footer.office" })}:<br /><br /></div>
                                    <div>{intl.formatMessage({ id: "footer.address" })}:</div>
                                </div>
                                <div className='mont-semi-bold-white-25px' style={{ width: 350, fontSize: 30 }}>
                                    <div>+1 604-291-0073</div>
                                    <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.emailC" }))}</div>
                                    <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.officeC" }))}</div>
                                    <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.addressC" }))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    );
});

export default Contact;