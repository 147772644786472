import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import policy from "../data/policy";
import ReactHtmlParser from "react-html-parser";

const PrivacyPolicy = (props) => {
    return (
        <div className="siteContent">
            <Header />
            <div style={{ padding: "50px 0px", width: "var(--max-width)", maxWidth: "90vw" }} className="margin-center mont-regular-normal-white-18px">
                {ReactHtmlParser(policy)}
            </div>
            <Footer />
        </div>
    )
}

export { PrivacyPolicy };