import React, { Component } from 'react';
import Header from '../components/layout/Header';
import ReactHtmlParser from 'react-html-parser';
import articles from '../data/articles';
import PressBodySection from '../components/layout/PressBodySection'
import MathJax from 'react-mathjax'
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { isMobile } from '../utils/MobileAdapt';

const Parser = ({ mString }) => {
    const iparser = new DOMParser();
    const parsedHtml = iparser.parseFromString(mString, 'text/html');
    const itemsArray = [];
    if (!parsedHtml || !parsedHtml.body) return null;

    parsedHtml.body.childNodes.forEach(item => {
        let type = 'normal';
        if (!item.outerHTML) return;
        if (item.outerHTML.includes('mathblock')) {
            type = 'mathblock';
            itemsArray.push({ type, html: item.innerHTML });
        } else if (item.outerHTML.includes('mathinline')) {
            type = "mathinline";
            let tempItem = item.innerHTML;
            itemsArray.push({ type, html: tempItem.split("$$") });
        } else {
            itemsArray.push({ type, html: item.outerHTML });
        }
    });
    console.log(itemsArray)

    return (
        <div style={{ fontSize: isMobile() ? 15 : 24, marginLeft: "auto", marginRight: "auto" }}>
            {itemsArray.map((item) =>
                item.type === 'mathblock' ? (
                    <MathJax.Provider>
                        <div style={{ overflowX: "auto" }}>
                            <MathJax.Node formula={item.html} />
                        </div>
                    </MathJax.Provider>

                ) : (
                    item.type === 'mathinline' ? (
                        <MathJax.Provider>
                            <p>
                                {item.html.map((section, index) => {
                                    return index % 2 == 1 ? <MathJax.Node inline formula={section} /> : section
                                })}
                            </p>
                        </MathJax.Provider>
                    ) : ReactHtmlParser(item.html))
            )}
        </div>
    );
};

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLocation: this.props.history.location.pathname
        }
    }

    render() {
        //if directed from other articles, need to refresh the page to make the equations show up.
        //in which case the component doesn't initiate anymore
        if (this.state.currentLocation != this.props.history.location.pathname) {
            this.setState({ currentLocation: this.props.history.location.pathname });
            //set time out to wait for the scroll to go back to top
            setTimeout(() => window.location.reload(true), 850);
        }
        const { params: { title } } = this.props.match;

        let article;
        articles.map((ar, _) => {
            if (ar.title.replace(/\s+/g, '-').toLowerCase() == title) {
                article = ar
            }
        });

        const fontSize = isMobile() ? "14px" : "20px";
        const ArticleWrapper = styled.div`img {margin-left:auto; margin-right:auto} h3 {text-align:left} tbody{font-size:${fontSize}} div{max-width:80vw}`;

        return (
            <div className='siteContent'>
                <Header pageName="Resources" />
                <div className='margin-center' style={{ lineHeight: 1.6, maxWidth: "90vw", width: "var(--max-width)" }}>
                    <h1 style={{ textAlign: "center", marginTop: 100 }}>{article.title}</h1>
                    <ArticleWrapper>
                        <Parser mString={article.content} />
                    </ArticleWrapper>
                    <h2 style={{ textAlign: "left" }}>{this.props.intl.formatMessage({ id: "article.hint" })}</h2>
                    <PressBodySection style={{ textAlign: "left" }} title={article.title} />
                </div>
            </div>
        );
    }
}

export default injectIntl(Article);
