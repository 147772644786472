function isInViewport(id) {
    const element = document.getElementById(id);
    if (!element) {
        return false;
    };
    const rect = element.getBoundingClientRect();
    return rect.top <= window.innerHeight;
};

function scrollShow(showMap, setShowMap) {
    for (let item in showMap) {
        if (isInViewport(item)) {
            setShowMap(showMap => {
                let temp = { ...showMap };
                temp[item] = true;
                return temp;
            });
        };
    };
};

export { isInViewport, scrollShow };