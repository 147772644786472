import { Map } from 'immutable';
import localeActions from './actions';
import AppLocale from '../../languageProvider';
import langList from '../../languageProvider/langList.json';

function initState() {
    let locale = localStorage.getItem('locale');
    for (let i = 0; i < langList.length; i++) {
        if (langList[i].key === locale) {
            return new Map({
                lang: locale,
                currentAppLocale: AppLocale[`${locale}`]
            });
        };
    };
    localStorage.setItem('locale', 'en_US');
    return new Map({
        lang: 'en_US',
        currentAppLocale: AppLocale.en_US,
    });

};

export default function LocaleReducer(state, action) {
    state = initState();
    switch (action.type) {
        case localeActions.CHANGE_LOCALE:
            localStorage.setItem('locale', action.lang);
            return state.set('lang', action.lang).set('currentAppLocale', AppLocale[`${action.lang}`]);
        default:
            return state;
    };
};