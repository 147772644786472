import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/scss/style.scss';
import './assets/css/master.css';
import "./Global.css";

const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));

const mapStateToProps = (state) => {
  return {
    currentAppLocale: state.ChangeLocale.get("currentAppLocale")
  };
}

const DashApp = connect(mapStateToProps)((props) => {
  const { currentAppLocale, store } = props;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentAppLocale]);

  return (
    <Provider store={store}>
      <IntlProvider
        key={currentAppLocale.locale}
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Router history={history}>
          <App />
        </Router>
      </IntlProvider>
    </Provider>
  );
})

root.render(
  <DashApp store={store} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
