import React, { useState, useEffect } from 'react';
import HubspotContactForm from '../../views/Hubspot';
import { injectIntl } from 'react-intl';
import ReactHtmlParser from "react-html-parser";

const Footer = injectIntl((props) => {
  const { intl } = props;
  const [media, setMedia] = useState("");

  return (
    <div className="footer margin-center">
      <div className="footer-flex">
        <div>
          <img alt="bluvec-logo" className="primary-white-logo" src="/images/primary-white-logo-new.png" />
          <div className="footer-left-info mont-bold-white-14px">
            <div className="footer-left-info-contact">
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.phone" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.email" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.web" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.address" }))}</div>
              <div style={{ marginTop: 28 }}>{ReactHtmlParser(intl.formatMessage({ id: "footer.office" }))}</div>
            </div>
            <div className="footer-left-info-contact" style={{ width: "180px" }}>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.phoneC" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.emailC" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.webC" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.addressC" }))}</div>
              <div>{ReactHtmlParser(intl.formatMessage({ id: "footer.officeC" }))}</div>
            </div>
          </div>
        </div>
        <div className="foot-right-column mont-light-white-14px">
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "header.product" })}</div>
          <a href='/Products/Overview'>{intl.formatMessage({ id: "header.overview" })}</a>
          <a href='/Products/Blucam'>{intl.formatMessage({ id: "home.hard.blucam" })}</a>
          <a href='/Products/Blusensor'>{intl.formatMessage({ id: "home.hard.blusensor" })}</a>
          {/* <a href='/Products/Blugun'>{intl.formatMessage({ id: "home.hard.blugun" })}</a> */}
          <a href='/Products/Blushield'>{intl.formatMessage({ id: "home.hard.blushield" })}</a>
          <a href='/Products/Blucase'>{intl.formatMessage({ id: "home.hard.Blucase" })}</a>
          <a href='/Products/Software'>{intl.formatMessage({ id: "home.hard.Software" })}</a>
        </div>
        <div className="foot-right-column mont-light-white-14px">
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "header.technology" })}</div>
          <a href='/Technology/Overview'>{intl.formatMessage({ id: "header.overview" })}</a>
          <a href='/Technology/DSI'>{intl.formatMessage({ id: "home.soft.dsi" })}</a>
          <a href='/Technology/TDOA'>{intl.formatMessage({ id: "home.soft.tdoa" })}</a>
          <a href='/Technology/RTI'>{intl.formatMessage({ id: "home.soft.rti" })}</a>
        </div>
        <div className="foot-right-column mont-light-white-14px">
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "header.solution" })}</div>
          <a href='/Solutions/Overview'>{intl.formatMessage({ id: "header.overview" })}</a>
          <a href='/Solutions/Airports'>{intl.formatMessage({ id: "header.solution.airport" })}</a>
          <a href='/Solutions/Correctional'>{intl.formatMessage({ id: "header.solution.prison" })}</a>
          <a href='/Solutions/Infrastructure'>{intl.formatMessage({ id: "header.solution.infra" })}</a>
          <a href='/Solutions/Public'>{intl.formatMessage({ id: "header.solution.public" })}</a>
          <a href='/Solutions/Military'>{intl.formatMessage({ id: "header.solution.military" })}</a>
          <a href='/Solutions/Commercial'>{intl.formatMessage({ id: "header.solution.commercial" })}</a>
        </div>
        <div className="foot-right-column mont-light-white-14px">
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "header.resource" })}</div>
          <a href='/Resources' onClick={() => sessionStorage.setItem("resourceSection", "marketing")}>{intl.formatMessage({ id: "res.market" })}</a>
          <a href='/Resources' onClick={() => sessionStorage.setItem("resourceSection", "news")}>{intl.formatMessage({ id: "res.social" })}</a>
          {/* <a href='/Resources' onClick={() => sessionStorage.setItem("resourceSection", "industry")}>{intl.formatMessage({ id: "res.industry" })}</a> */}
          <a href='/Resources' onClick={() => sessionStorage.setItem("resourceSection", "press")}>{intl.formatMessage({ id: "res.press" })}</a>
        </div>
        <div className="foot-right-column mont-light-white-14px">
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "header.about" })}</div>
          <a href='/About-us/company'>{intl.formatMessage({ id: "header.about.company" })}</a>
          <a href='/About-us/career'>{intl.formatMessage({ id: "header.about.career" })}</a>
        </div>
        <div className='footer-hubspot'>
          <div className="mont-bold-denim-14px" style={{ marginBottom: 10 }}>{intl.formatMessage({ id: "footer.subscribe" })}</div>
          <HubspotContactForm
            region="na1"
            portalId="22076592"
            formId="b518ec4b-9dce-4756-9f2d-64cf94389854"
            target="hubspotSubscribe1"
          />
        </div>
      </div>
      {/* <div className="footer-icons">
        <div>
          <img width={70} alt="FCC" src="/images/FCC.png" />
        </div>
        <div style={{ width: 240 }} >
          <img alt="NATO_OTAN_CODE_L0TM7" src="/images/NATO_ICON.png" />
          <div className="footer-icons-ncage">NCAGE L0TM7</div>
        </div>
        <div>
          <img width={190} alt="CADS" src="/images/CADS.png" />
        </div>
      </div> */}
      <div className="footer-right-operation-social">
        <a href='https://www.linkedin.com/company/bluvec/mycompany/' target="_blank"
          onMouseEnter={() => setMedia("linkedin")} onMouseLeave={() => setMedia("")}>
          {media === "linkedin" ? <img className="footer-right-operation-icon" src="/images/linkedin-hover.png" /> : <img className="footer-right-operation-icon" src="/images/linkedin.png" />}
        </a>
        <a href='https://twitter.com/BluvecTech' target="_blank">
          {<img className="footer-right-operation-icon" src="/images/x.png" />}
        </a>
        <a href="https://www.youtube.com/channel/UCwGVIU5AC-0ZJsdm_bqNXGg" target="_blank"
          onMouseEnter={() => setMedia("youtube")} onMouseLeave={() => setMedia("")}>
          {media === "youtube" ? <img className="footer-right-operation-icon" src="/images/youtube-hover.png" /> : <img className="footer-right-operation-icon" src="/images/youtube.png" />}
        </a>
      </div>
      <img className="footer-divider" src="/images/footer-divider.svg" />
      <p className="footer-terms">
        ©BLUVEC | <a href='/Policy-and-terms'>Terms &amp; Conditions</a> | <a href='/Policy-and-terms'>Privacy Policy</a> | <a>Patents</a>
      </p>
    </div>
  );
});

export default Footer;
