const articles = [
    {
        "title": "General Regulations for Flying a Drone in Canada",
        "coverImage": "/images/1.png",
        "intro": "article.regulation.intro",
        "order": 4,
        "content":
            `
          <img src="/images/1.png" width="50%"/>
          <br> 
          <p>The following rules apply to all drone operations:</p>
          <p>&middot; All drones that weigh 250 g and 25 kg should be documented with <u><a href="https://tc.canada.ca/en">Transport Canada.</a></u></p>
          <p>Pilots have to mark their drones with their registration digits prior they fly.</p>
          <p>&middot; All pilots of drones that weigh between 250 g and 25 kg should earn a drone pilot certification.</p>
          <p>&middot; Fly your drone as long as you can see it at all times.</p>
          <p>&middot; Fly below 122 meters (400 feet) in the sky.</p>
          <p>&middot; Fly away from spectators, at a minimum space of 30 meters for basic operations.</p>
          <p>&middot; Do not fly in the area of emergency operations or advertised events.</p>
          <p>&middot; Avoid forest fires, outdoor shows, and parades.</p>
          <p>&middot; Do not fly within 5.6 kilometers from airports or 1.9 kilometers from heliports.</p>
          <p>&middot; Fly far away from other aircraft.</p>
          <p>&middot; Do not fly anywhere near airplanes, helicopters, and other drones.</p>
          <p>&middot; Always respect the privacy of others while flying.</p>
          <p><strong>And don&apos;t forget about No Drone Zones!&nbsp;</strong></p>
          <p>&ldquo;No drone zones&apos;&apos; are areas where it may be unsafe or illegal to fly your drone. Below are no drone fly zones:</p>
          <p>&middot; Around airports and aerodromes.</p>
          <p>&middot; In busy, populated areas.</p>
          <p>&middot; In national parks.</p>
          <p>&middot; Over border crossings.</p>
          <p>Extra rules apply depending on your type of operation. The regulations introduce two types of drones operations: basic and advanced. The classes are based on distance from bystanders and airspace rules.</p>
          <p><strong>Resolve if Your Drone Operations are Basic or Advanced</strong></p>
          <p>If you meet all three of these circumstances, you&rsquo;re performing basic operations:</p>
          <p>&middot; You fly your drone in uncontrolled airspace.</p>
          <p>&middot; You fly your drone more than 30 meters horizontally from bystanders.</p>
          <p>&middot; You never fly your drone over bystanders.</p>
          <p>If you do not meet any one of these three conditions, you are conducting advanced operations.</p>
          <p>If you meet any one of these circumstances, you are conducting advanced operations:</p>
          <p>&middot; You want to fly in controlled airspace.</p>
          <p>&middot; You want to fly over bystanders.</p>
          <p>&middot; You want to fly within 30 meters of bystanders (measured horizontally).</p>
          <p><strong>Rules for Basic Drone Operations</strong></p>
          <p>In addition to the general rules for flying a drone in Canada, pilots conducting basic drone operations must:</p>
          <p>&middot; Pilots conducting basic operations need a Pilot Certificate &ndash; Basic Operations.</p>
          <p>&middot; Be able to show your Pilot Certificate &ndash; Basic Operations and proof of registration when you fly.</p>
          <p><strong>Rules for Advanced Drone Operations</strong></p>
          <p>In addition to the general rules for flying a drone in Canada, pilots operating advanced drone functions must:</p>
          <p>&middot; Pilots conducting advanced operations need a Pilot Certificate &ndash; Advanced Operations. They must pass the Small Advanced Exam and an in-person flight review to get this certificate. The flight review will assess a pilot&rsquo;s ability to operate their drone safely.</p>
          <p>&middot; Survey the area where you will fly. Take note of any obstacles, such as buildings and power lines.</p>
          `
    },
    {
        "title": "UAS & C-UAS",
        "coverImage": "/images/2.png",
        "intro": "article.drone.intro",
        "order": 5,
        "content":
            `
      <img src="/images/2.png" width="50%"/>
      <br> 
      <p>Unmanned Aerial Systems (UAS) are gaining popularity worldwide. The UAS industry is rapidly growing, and drones are being used for a variety of purposes, including agriculture, land surveying, filming, photography, monitoring, surveillance, transportation, defense, etc. While drones can help to augment many types of activites, their usage is also accompanied by significant risks. Whether intentional or not, drones can lead to dangerous collisions, accidents and intrusions. They can also be used for malicious purposes and serve as tools for illegal activities. Counter-UAS (C-UAS) systems are therefore essential for minimizing drone threats. Furthermore, many traditional security systems are vulnerable when it comes to aerial security. C-UAS technologies can be integrated into existing infrastructures to provide more reliable protection.</p>
      <p>C-UAS systems can be categorized based on their platforms, capabilities and technologies.</p>
      <p><strong>Different platforms for C-UAS systems include:</strong></p>
      <p>- Ground-based: either fixed location or mobile deployment (e.g. mounted on vehicles) </p>
      <p>- Hand-held</p>
      <p>- UAS-based: e.g. using a drone to respond to an intruding UAS </p>
      <p><strong>Different capabilities for C-UAS systems include:</strong></p>
      <p>- Detecting: detecting drones and alerting the user</p>
      <p>- Tracking: tracking the locations and flight paths of drones</p>
      <p>- Defeat: incapacitate drones and disrupt their flight trajectories</p>
      <p><strong>Different technologies used in C-UAS systems include:</strong></p>
      <p>- Wireless or radio frequency (RF) </p>
      <p>- Electro-optical and infrared (IR) </p>
      <p>- Radar</p>
      <p>- Acoustic</p>
      `
    },
    {
        "title": "Pose Estimation for Drones",
        "coverImage": "/images/post-estimate/x30cropped.gif",
        "intro": "article.pose.intro",
        "order": 2,
        "content":
            `
        <head>
        <style>
        body{
        background-color: black;
        color: #9ca9b3;
        }
        img.center75{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 75%;
        }
        img.center50{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }
        img.center25{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 25%;
        }
        img{
          display: inline-block;
        }
        img.background{
            background-color: white;
        }
        p{
          margin-top: 4px;
          margin-bottom: 2px;
        }
        div.center{
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        </style>
        </head>
        <body>        
        <p>
            In this article, we will introduce the interesting problem of pose estimation for drones, and share some of the
            research we have done in this area. The pose of a drone can serve as an important feature for us to further analyze
            the high-level behavior of the drone, such as its distance and flight trajectory. The pose refers to the angle of
            rotation of the drone on three axes, relative to the camera position. The drone displayed below has a pose angle of
            (0,0,0):
            <img src="/images/post-estimate/zero.png" alt="Drone having a pose of (0, 0, 0)" width="25%">
        </p>
        
        <p>
            This problem may seem straightforward at first glance. We can easily guess the pose if the drone rotates along one axis.
            The following three cases are the drone rotating along the x, y and z-axis respectively for 30 degrees. The pose
            is intuitive and easy to guess.
        </p>
        
        <p>
            <div style="text-align:center;">
            <img src="/images/post-estimate/x30.gif" alt="Drone having a pose of (30, 0, 0)" width="25%">
            Rotate along the x-axis for 30 degrees: (30, 0, 0)
            </div>
        </p>
        
        <p>
            <div style="text-align:center;">
            <img src="/images/post-estimate/y30.gif" alt="Drone having a pose of (0, 30, 0)" width="25%">
            Rotate along the y-axis for 30 degrees: (0, 30, 0)
            </div>
        </p>
        
        <p>
            <div style="text-align:center;">
            <img src="/images/post-estimate/z30.gif" alt="Drone having a pose of (0, 0, 30)" width="25%">
            Rotate along the z-axis for 30 degrees: (0, 0, 30)
            </div>
        </p>
        
        <p>
            The problem becomes more complicated when the drone rotates along two or even three axes at the same time. In this
            case, it is not so intuitive for humans to determine the drone's pose. The drone shown below has a pose of
            (0, 30, 180), instead of (0, -30, 180). After the drone rotates 180 degrees along the z-axis, the rotation along
            the y-axis will be in a reverse direction.
            <img src="/images/post-estimate/4.gif" alt="Drone having a pose of (0, 30, 180)" width="25%">
        </p>
        
        <p>
            While challenging for humans, pose estimation is a suitable task  for AI. Therefore, our goal is to use a machine
            learning approach to tackle this problem.
        </p>
        
        <p>
            In recent decades, lots of research has been carried out on extracting useful knowledge from large amounts of data.
            <a href="https://www.nature.com/articles/nature14539">Deep learning</a> is one of the most popular and promising
            methods. It takes advantage of the extremely powerful processing units we have nowadays and also the huge amount of
            available data for the model to conduct the learning process. We use this approach to tackle our pose estimation
            problem. 
        </p>
        
        <mathinline>
        To reduce the difficulty of the problem, we adopt a supervised learning approach. This involves learning
            a mapping function $$f$$ that has a good performance on the test dataset $$\\{x_i^{test},y_i^{test}\\}|_{i=1}^{n^{test}}$$,
            i.e. $$f(x^{test})=y'\\approx y^{test}$$, given the training dataset $$\\{x_i^{train},y_i^{train}\\}|_{i=1}^{n^{train}}$$. Each data record is
            a $$(x,y)$$ pair, where $$x$$ is the image of the drone, and $$y$$ is the pose
            information.
        </mathinline>
        
        <h3>Data Generation</h3>
        <p>
            We generate a synthetic image dataset using a 3D model of a drone and use the dataset to train our pose estimation
            model. We show some data records of the synthetic image dataset in the following visualization. The numbers in red are the
            ground truth of the x-y-z rotation angles of each drone.
            <img src="/images/post-estimate/5.jpg" alt="The synthetic dataset" width="75%">
        </p>
        
        <h3>Model Design</h3>
        <p>
            There are many general-purpose neural network architectures suitable for various computer vision tasks, such as
            <a href="https://arxiv.org/abs/1512.03385">ResNet</a>, <a href="https://arxiv.org/abs/1409.1556">VGGNet</a>, and
            <a href="https://arxiv.org/abs/1905.11946v5">EfficientNet</a>. We choose EfficientNet as our model’s backbone
            because of its compactness and good performance. We slightly modify the model architecture by replacing the last
            layer with a series of layers, as shown below. The final output has a dimension of three, which represents the
            x-y-z rotation angles of the pose.
            <img src="/images/post-estimate/6.png" alt="Model architecture" width="50%">
        </p>
        
        
        <h3>Learning Objective: Losses and Evaluation Metrics</h3>
        <p>
            We first compute the error between the predicted value and the actual value. We define the loss to be the root
            mean square of the error.
        </p>
        <mathblock>loss=\\sqrt{\\frac{\\sum_i^n(y_i^{test}-y_i')^2}{n}}</mathblock>
        <p>
            In addition, we can add a regularization loss to prevent overfitting. Specifically, it is the sum of the Frobenius
            norm of the parameters of the last layer. Therefore, the final loss becomes:
        </p>
        <mathblock>loss=\\sqrt{\\frac{\\sum_i^n(y_i^{test}-y_i')^2}{n}}+loss^{\\textbf{reg}}</mathblock>
        
        <mathinline>
            The learning objective is to minimize the loss. In other words, we want to reduce the error between the predicted
            value and the actual value. The loss itself is not intuitive for measuring the exact performance of our model.
            Therefore, we also define a human readable evaluation metric. We allow a small error $$\\epsilon$$, e.g.
            5 degrees, for each angle and if the error along all $$x-y-z$$ axes are within this threshold, we count it as a correct
            prediction. We calculate the accuracy by dividing the number of correct cases by the total number of test cases.
        </mathinline>

        <mathblock>
        accuracy = \\frac{\\sum_{i=1}^{n^{test}}\\textbf{l}_{{\\{y_j'|\\epsilon\\geq|y_j'-y_j^{test}|\\}}|_{j=1}^{n^{test}}}(y_i')}{n^{test}}
        </mathblock>
        
        <p>
            Once we have the dataset, the model, the learning objective, and the evaluation metric, we can start to train our
            model. We split the dataset into three portions: training, validation, and test. We use the training data to adjust
            the model’s parameters. The validation data is used to assess the model during training.  After training is
            complete, we measure the actual performance of our model via the test dataset. We terminate training when the
            validation performance stops improving, as shown in the example below. This strategy is called early stopping, and
            it is used to address the problem of
            overfitting during model training. Overfitting means that the model only has good performance on the training
            dataset, but fails to generalize to other data.
            <img src="/images/post-estimate/7.png" alt="The problem of Overfittings" style="background-color: white;" width="25%">
        </p>
        
        <h3>Experiment Results</h3>
        <p>
            The training outcome of our model is quite successful. The validation accuracy exceeds 90%. The test accuracy is
            1-2% lower than the validation accuracy.
        
            <div class="center">
                <img src="/images/post-estimate/8.gif" alt="Disgram showing the training loss and validation accuracy" width="50%">
                The figure above shows the training loss (blue line) and the validation accuracy (orange line) in each training step.
            </div>
        </p>
        
        <p>
            We also checked the predictions manually. Most of the predictions are quite close to the ground truth.
            In the figure below, for each drone, the ground truth and predicted poses are shown in blue and red text respectively.
            <div class="center">
                <img src="/images/post-estimate/9.jpg" alt="Ground truth vs Prediction on test dataset" width="75%">
            </div>
        </p>
        
        
        <p>
            Despite the good performance on both the validation and test dataset, the model is not robust enough to be put into
            the production environment. This is because of the significant visual differences between synthetic and real data.
            There is a gap between their data distributions. We can expect a huge performance drop when the model is predicting
            on real data. In the next article, we will try to address this problem by applying transfer learning techniques to
            our model. Stay tuned!
        </p>
        
        <h3>About the Author</h3>
        <div style="height:300px; padding:30px">
        <img src="/images/post-estimate/avatar.jpeg" style='float:left; width:200px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:30px'>Sam Kwun-Ping, Lai</p>
        <p>Machine learning engineer at Bluvec Technologies Inc. He obtained his PhD at The Chinese University of Hong Kong. 
        He is passionate about natural language processing (NLP), computer vision, and transfer learning.</p>
        </div>
        </body>        
        `
    },
    {
        "title": "Locating Drones by TDOA Measurements",
        "coverImage": "/images/tdoa/DEMOcropped.gif",
        "intro": "article.tdoa.intro",
        "order": 2,
        "content":
            `
    <head>
        <style>
        body{
        background-color: black;
        color: #9ca9b3;
        }
        img.center75{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 75%;
        }
        img.center60{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 60%;
          }
          img.center50{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
          }
        img.center40{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 40%;
        }
        img.center25{
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 25%;
        }
        img {
          display: inline-block;
        }
        img.background{
            background-color: white;
        }
        p{
          margin-top: 4px;
          margin-bottom: 2px;
        }
        div.center{
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        
        </style>
        </head>
    <body>   
    <h3>Introduction</h3>   
    
    <p>
    The radio frequency communication signal from a drone to its controller is always framed by some 
    form of synchronization sequence which is used by the controller’s receiver to acquire and maintain 
    the timing of the radio link. A sensor trained to recognize this synchronization sequence can eavesdrop 
    this radio link and estimate it’s timing relative to the sensor’s time base. Then two such sensors with 
    a common time base can measure a time-difference-of-arrival (TDOA) from the drone and, using the radio 
    wave propagation speed, translate this to a range difference. With the drone and the sensors considered 
    to be in a plane, the range difference defines a hyperbola of points passing between the two sensors, 
    with each point representing a possible location of the drone. Either of these sensors paired with a 
    third sensor on the same time base can determine a second hyperbola of possible drone locations, and 
    the intersection of the two hyperbolas would determine the drone location uniquely. Finding the intersection 
    of the two hyperbolas can be referred to as solving the range difference equations.
    </p>

    <p>
    Obviously, the measurement procedure is subject to stochastic errors. Therefore, it is imperative that 
    the method used to solve the range difference equations minimizes the effect of the measurement errors. 
    Furthermore, any such solver should provide not only the desired drone location, but also, given certain 
    assumptions on the measurement error statistics, an estimate of the accuracy of the solution. For the use 
    case in which a time series of measurements are made, and a corresponding series of positions are estimated, 
    the estimates of the position uncertainties can aid a subsequent tracking filter.
    </p>
    
    <p>
    The range difference equations can be solved either in closed form or iteratively. We have chosen the Gauss-Newton 
    iterative method because it has the following strong advantages over the closed form methods: 
    </p>

    <ul>
      <li>uniform derivation for any number of measurements</li>
      <li>leads to simple and stable code</li>
      <li>gives the error covariance of the estimated location</li>
      <li>minimizes the mean squared error</li>
    </ul>

    <p>
    The disadvantage of an iterative method versus a closed form algorithm is that the iterative method requires an 
    initial guess, and if that guess is poor the convergence is not guaranteed. However, we find that the method almost 
    always converges, and the solution is then independent of the initial guess.
    </p>

    <h3>Gauss-Newton Method</h3>

    <mathinline>
    Consider the two-dimensional problem in which the sensors and the target drone are all in the same horizontal plane. 
    Later in the application of the resulting equations, a height for the drone will be included as a parameter in the measurement 
    model thus introducing some systematic error.  Let $$x$$ denote the position of the drone, and let $$(x_i,y_i)$$ denote the position 
    of sensor $$i$$, for $$i=1,2,...,N$$; where $$N$$ is the number of sensors in the detection network. In terms of these positions, the distance 
    differences relative to sensor 1 are
    </mathinline>

    <mathblock>f_i(x,y) = r_i(x,y) - r_1(x,y)\\quad\\quad\\quad i =2,...,N\\quad\\quad\\quad\\quad(1)</mathblock>   
    
    <p>
    where
    </p>
    
    <mathblock>r_i(x,y) = \\sqrt{(x-x_i)^2 + (y-y_i)^2}\\quad\\quad\\quad i = 1,2,...,N\\quad\\quad\\quad\\quad(2)</mathblock>
    

    <mathinline>
    With a measurement $$m_i$$ of the distance difference, then
    </mathinline>
    
    <mathblock>f_i(x,y) = m_i - e_i \\quad\\quad\\quad i = 2,...,N \\quad\\quad\\quad\\quad\\quad(3)</mathblock>

    <mathinline>
    where $$e_i$$ is the measurement error. We measure time differences of arrival to make range difference measurements which are the 
    time-of-arrival difference measurements multiplied by the the propagation speed, which we take to be the speed of light
    : $$r_i - r_j = (t_i - t_j)v_p$$, with $$v_p = 3*10^8$$ m/s.
    </mathinline>
    
    <mathinline>
    The Gauss-Newton method is an iterative solution for the position of the drone.  With the $$0$$-th estimate being an initial guess, 
    denote  the $$v$$-th  estimate by $$x(v),y(v)$$, and make a new estimate
    </mathinline>
    
    <mathblock>
    x(v+1) = x(v) + d_x,  \\quad\\quad    y(v+1) = y(v) + d_y  \\quad\\quad\\quad(4)
    </mathblock>

    <mathinline>
    using the first order Taylor series expansion for $$f_i$$
    </mathinline>
    
    <mathblock>
    f_i(x,y) = f_i(x(v),y(v)) + d_x p_xf_i(x(v),y(v)) + d_y p_yf_i(x(v),y(v))  \\quad\\quad\\quad(5)
    </mathblock>

    <mathinline>
    where we denote the partial derivative of $$f$$ by $$x$$ with $$p_xf$$. For the initial guess we shall use the mean of the sensor locations.
    </mathinline>

    <p>Define</p>
    <mathblock>
    f_i(v) = f_i(x(v),y(v))\\quad\\quad\\quad  i = 2,...,N
    </mathblock>

    <p>and</p>
    <mathblock>
    a_{i1} = p_xf_i(x(v),y(v)),  \\quad\\quad    a_{i2} = p_yf_i(x(v),y(v))
    </mathblock>

    <p>From (1) and (2)</p>
    <mathblock>
    a_{i1} = \\frac{x(v) - x_i}{r_i(x(v),y(v))}  -  \\frac{x(v) - x_1}{r_1(x(v),y(v))}
    </mathblock>

    <mathblock> 
    a_{i2} = \\frac{y(v) - y_i}{r_i(x(v),y(v))}  -  \\frac{y(v) - y_1}{r_1(x(v),y(v))}
    </mathblock>

    <mathinline>for $$i=2,...,N.$$</mathinline>

    <p>Then substituting equation (5) into equation (3), find (3) can be written in matrix form as</p>
    
    <mathblock>
    \\textbf{Ad = b - e} \\quad\\quad\\quad\\quad\\quad\\quad(6)
    </mathblock>

    <p>where</p>
    
    <mathblock>
    \\textbf{A} = [ a_{21}, a_{22}; ... ; a_{N1}, a_{N2};], \\quad\\quad\\textbf{d} = [ d_x;  d_y; ],
    </mathblock>

    <mathblock>
    \\textbf{b} = [ m_2 - f_2(v); ... ;  m_N - f_N(v);],\\quad\\quad\\textbf{e} = [ e_2; ... ; e_N;].
    </mathblock>

    <mathinline>
    Let $$\\textbf{R}$$ denote the measurement error covariance matrix with its i-j-th term defined to be the expectation value $$(\\textbf{e}_i,\\textbf{e}_j)$$. Suppose 
    that the range measurements are all independent of one another; and denote the variance of a range measurement by $$s_r^2$$. Then since the range difference measurements 
    are obtained by taking the difference of two range range measurements:
    </mathinline>

    <mathblock>\\textbf{R}_{i,j} = 2s_r^2 \\quad\\quad  \\textbf{for}\\quad i = j, \\quad\\textbf{and}\\quad i\\in[1,N-1]</mathblock>

    <mathblock>\\textbf{R}_{i,j} = s_r^2  \\quad\\quad\\ \\textbf{for}\\quad  i\\neq j, \\quad\\textbf{and}\\quad i,j\\in[1,N-1]</mathblock>

    <mathinline>The minimum mean squared error solution to (6) for $$\\textbf{d}$$ is given by the Penrose solution:</mathinline>
    
    <mathblock>
    \\textbf{d} = (\\textbf{A}^T\\textbf{R}^{-1}\\textbf{A})^{-1}\\textbf{A}^T\\textbf{R}^{-1}\\textbf{b} \\quad\\quad\\quad(7)
    </mathblock>

    <p>
    with the error covariance matrix of the position estimate given by
    </p>
    
    <mathblock>
    \\textbf{Q} =  (\\textbf{A}^T\\textbf{R}^{-1}\\textbf{A})^{-1} \\quad\\quad\\quad\\quad\\quad(8)
    </mathblock>

    <p>
    To improve the convergence properties of this iterative solution, we will regularize (7) to
    </p>
    
    <mathblock>
    \\textbf{d} = (\\textbf{A}^T\\textbf{R}^{-1}\\textbf{A}+c\\textbf{I})^{-1}(\\textbf{A}^T\\textbf{R}^{-1}\\textbf{b} - c(\\textbf{x}(v) - \\textbf{x}_r)) \\quad\\quad\\quad\\quad (9)
    </mathblock>

    <mathinline>
    where $$ \\textbf{x}(v) = [x(v); y(v);]$$, $$\\textbf{I}$$ is the 2x2 matrix identity, $$c$$ is the regularization coefficient and 
    $$\\textbf{x}_r$$ is the regularization point which we also take to be the mean of the sensor locations.
    </mathinline>

    <h3>Simulation Results</h3>
    <p>
    We report results for a Monte Carlo simulation which emulates a scenario in which all sensors have a line-of-sight propagation 
    path from the target drone. We add white Gaussian noise to the exactly calculated time-of-arrival measurements with a standard 
    deviation given by an expected typical synchronization error. For the latter we take, as an example, a DJI Mavic drone detector 
    with sampling rate 30.72 MHz; and take the expected typical synchronization error to be 1, 2, or 3 sampling intervals. These correspond 
    to time-of-arrival measurement errors of 32.5, 65, and 97.5 nano seconds, respectively. The timing error includes the error in synchronizing 
    the time-base’s of the sensors. Using GPS, the sensors can be synchronized to within 10 to 20 nano seconds. The drone height was taken to be 
    10 meters.
    </p>

    <p>
    The simulation arithmetic was done in double precision, and the regularization coefficient was taken to be 0.0000001. This coefficient resulted 
    in low bias for the estimated position and near 100% convergence success rate of the Gauss-Newton iterator for simulated target drone locations 
    shown in the following.
    </p>

    <p>
    Two network configurations were considered: a ring of 4 sensors evenly spaced at radius 707 meters, and a ring of 5 sensors evenly spaced at 
    radius 707 meters. In both cases, a set of positions of a stationary drone consisting of a semi-regular grid of angles at each of the radii 
    of 200, 400, 600, 800, 1000, and 1200, meters were chosen as shown in the following two plots. The plots show the simulated sensor locations, 
    drone locations, and estimated drone locations for 20 simulation trials at each simulated drone location with a time-of-arrival standard deviation 
    of 65 nano seconds. The time-of-arrival error corresponds to a range measurement error of 19.5 meters.
    </p>

    <p>
            <div style="text-align: center;">
            <img src="/images/tdoa/estimated_position_1.png" alt="Drone having a pose of (0, 0, 0)" class="center40">
            <em>Estimated positions of drone with 4 sensors</em>
            </div>
    </p>

    <p>
            <div style="text-align: center;">
            <img src="/images/tdoa/estimated_position_2.png" alt="Drone having a pose of (0, 0, 0)" class="center40">
            <em>Estimated positions of drone with 5 sensors</em>
            </div>
    </p>

    <p>
    The root-mean-square (RMS) localization estimation error shown in the next two plots was measured as a function of drone distance from the 
    center of the sensor network by running the simulation for 1000 trials at every above described drone location. 
    </p>

    <p>
            <div style="text-align: center;">
            <img src="/images/tdoa/LAVD_1.png" alt="Drone having a pose of (0, 0, 0)" class="center40">
            <em>RMS localization estimation error with 4 sensors</em>
            </div>
    </p>

    <p>
            <div style="text-align: center;">
            <img src="/images/tdoa/LAVD_2.png" alt="Drone having a pose of (0, 0, 0)" class="center40">
            <em>RMS localization estimation error with 5 sensors</em>
            </div>
    </p>

    <p>
    Within the network perimeter, the RMS localization error is less than 50 meters for a 2-sample time-of-arrival measurement error 
    standard deviation. Beyond the perimeter of the sensor network, the estimation accuracy degrades rapidly. However, the accuracy of 
    the angular component is fairly constant as a function of distance, even for distance large compared to the size of the sensor network. 
    The analogous 4-sensor simulation with all parameters the same, except the drone distances which are taken over the larger range of 750 
    to 4500 meters, shows an RMS error of less than 2 degrees over the simulated range of drone distances, even at 3-sample (97.5 nano seconds) 
    time-of-arrival measurement error. This means the method is useful even well beyond the sensor perimeter.
    </p>

    <div style="text-align: center;">
            <img src="/images/tdoa/AAVD.png">
            <em>RMS angular estimation error with 4 sensors</em>
    </div>

    <h3>Algorithm in Action</h3>
    <p>
    We have successfully productized this algorithm via implementation on our <a href="/Products">Blusensor A1000</a>. Below is a screen recording of the web display 
    during a field test in which a DJI Mavic drone is flown in a flight-path that circles once within the 150 meter by 300 meter area shown in 
    the display, with the 4 sensors near the perimeter of this area:
    </p>

    <p>
            <div style="text-align: center;">
            <img src="/images/tdoa/DEMO.gif" alt="Drone having a pose of (0, 0, 0)" class="center75">
            </div>
    </p>

    <p>
    The trace was derived from time-of-arrival measurements feeding the TDOA localizer as described in this article. The outputs of the localizer 
    then drove a multi-target tracking filter and an interpolator. Stay tuned for an upcoming article at this site describing the Kalman 
    multi-target tracking filter.
    </p>

    <h3>About the Author</h3>

    <div style="height:300px; padding:30px">
        <img src="/images/tdoa/RobertAvatar.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:-10px'>Robert G. Link</p>
        <p>Principal Research Scientist for the Wireless Security Group at Bluvec Technologies Inc. He obtained his PhD at the University of British 
        Columbia in the Department of Physics. He works on signal processing algorithms for wireless communication systems, with recent emphasis on 
        detection and tracking system design and implementation.</p>
    </div>
    </body>
    `
    },
    {
        "title": "Multi-Target Tracking",
        "coverImage": "/images/Multi-target_Tracking/Multi-TargetTrackingCover.png",
        "intro": "article.multi.intro",
        "order": 2,
        "content":
            `
    <p>Multi-Target Tracking (MTT) systems are comprised of one or more sensors, which generate multiple measurements from multiple targets, 
    and a central processing unit which maintains one or more tracks, each representing an estimate of the state of a unique target. After 
    each sensor scan step, the central processor assigns measurements to tracks, and updates each track using its assigned measurements.
    </p>
    
    <p>
    We only consider point target tracking, whereby a single sensor generates at most one measurement from each target in one scan step. No 
    measurement for a target from a sensor scan can occur due to a missed detection. Also a sensor can generate measurements that do not 
    correspond to true targets due to false detections. The main difference between the various types of multi-point target trackers is the 
    method used to make the measurement-to-track assignments. There are three such types:
    </p>

    <p style="color:white;font-size:22px;margin-bottom: 0px;"><b>1. Global Nearest Neighbor</b></p>
    <p> Nearest measurements are assigned to existing tracks and unassigned measurements spawn new tentative tracks by minimizing an overall 
    cost function that considers all possible measurement-to-track assignments, except those assignments which contribute more than a gating 
    threshold amount to the cost function.</p>

    <p style="color:white;font-size:22px;margin-bottom: 0px;"><b>2. Joint Probability Data Association</b>
    <p>All measurements make weighted contributions to a track based on their probability of association, provided that the probability of 
    association is greater than a gating threshold. A measurement with a total probability of association lower than a second threshold 
    spawns a new tentative track.</p>

    <p style="color:white;font-size:22px;margin-bottom: 0px"><b>3. Track Oriented Multiple Hypothesis</b></p>
    <mathinline>A measurement $$M_i$$ is assigned to track $$T_j$$ resulting in hypothesis $$T_{i,j}$$, provided that the distance of $$M_i$$ is within a gating threshold 
    of $$T_j$$. A measurement whose distances to existing tracks are larger than a second threshold spawns a new tentative track. Hypotheses are 
    propagated between scans which allows data associations to be postponed for some specified number of scan steps.</mathinline>

    <mathinline>
    Tentative tracks are confirmed, and both tentative and confirmed tracks are deleted, by track scoring criteria. A discrete track score 
    can defined as the number times it has been assigned a measurement in the the last $$N$$ updates. A soft track score can be defined as an 
    integrated probability that the track is a real target, or can be defined as the ratio of the probability that the track is a real target 
    to the probability that the track is false. There can be different scoring rules and thresholds for confirmation and deletion. For 
    example, the score for confirming a track may be computed over the last $$N_c$$ updates, while the score for deleting a track is computed over 
    the last $$N_d$$ updates. Similarly, the probabilities in soft track scoring may be computed over different trace-back lengths for confirmation 
    and for deletion.
    </mathinline>

    <p>
    The basis of all the multi-target trackers is the Kalman filter or one of its generalizations. The tracking filter predicts tracks to 
    the current time so that a distance from a track to a measurement can be calculated and track assignments be made. If no measurement is 
    assigned to a track, the filter updates the track state by setting it equal to the predicted track state. If a measurement is assigned to 
    a track, the filter updates the track state by correcting the predicted track with its assigned measurement.
    </p>

    <h3>Kalman Filter</h3>
    <mathinline>The Kalman filter is model-based, with state transition model $$F$$, and measurement model $$H$$. Let $$x(k)$$ represent the track 
    state at time $$k$$.</mathinline>

    <mathinline>The state $$x(k+1)$$ at time instant $$k+1$$ evolves from the state $$x(k)$$ at time instant $$k$$ according to:</mathinline>
    <mathblock>x(k+1) = F(k+1) * x(k) + W(k+1)</mathblock>
    <mathinline>where $$F(k)$$ is the state transition matrix, and $$W(k)$$ is the process noise with covariance $$Q(k)$$.</mathinline>

    <mathinline>A measurement $$z(k)$$ at time instant $$k$$ of the state $$x(k)$$ is made according to:</mathinline>
    <mathblock>z(k) = H(k) * x(k) + v(k) </mathblock>
    <mathinline>$$H(k)$$ is the measurement matrix, and $$v(k)$$ is the measurement noise with covariance $$R(k)$$.</mathinline>

    <p style="color:white;font-size:22px;"><b>Example: constant velocity in one dimension</b></p>
    <p>Constant velocity one-dimensional motion has the two-dimensional state:</p>
    <mathblock>x(k) = [ x; v;]</mathblock>
    <mathinline>where $$x$$ is the distance from the origin and $$v$$ is the velocity.</mathinline>

    <p>The state transition matrix is:</p>
    <mathblock>F(k) = [ 1, dt; 0, 1; ]</mathblock>
    <mathinline>where $$dt$$ is time interval between time instances $$k$$ and $$k+1$$.</mathinline>

    <p>If we only measure distance, the measurement matrix is:</p>
    <mathblock>H(k) = [1, 0]</mathblock>

    <p>We use Matlab style notation for matrices, with commas separating elements of a row, and semi-colons marking the end of rows.</p>

    <p style="color:white;font-size:22px;"><b>Filter Equations</b></p>
    <p>The filter maintains an estimate of the state and the state covariance. We use the notation:</p>
    <mathinline>$$x(k|k)$$:  estimate of $$x(k)$$ given measurements $$z(k), z(k-1), ...$$</mathinline>
    <mathinline>$$x(k+1|k)$$:  estimate of $$x(k+1)$$ given measurements $$z(k), z(k-1), ...$$</mathinline>
    <mathinline>$$P(k|k)$$:  estimate of covariance of $$x(k|k)$$</mathinline>
    <mathinline>$$P(k+1|k)$$:  estimate of covariance of $$x(k+1|k)$$</mathinline>

    <mathinline>Given $$x(k|k), P(k|k)$$, and a new measurement $$z(k+1)$$, the filter produces updated state $$x(k+1|k+1)$$ and updated 
    state covariance $$P(k+1|k+1)$$ by following equations:</mathinline>

    <mathinline>1. State prediction:    $$x(k+1|k) = F(k)*x(k|k)$$</mathinline>

    <mathinline>2. Covariance prediction:    $$P(k+1|k) = F(k)*P(k|k)*F’(k) + Q(k)$$</mathinline>

    <mathinline>3. Measurement prediction:    $$z(k+1|k) = H(k)*x(k+1|k)$$</mathinline>

    <mathinline>4. Measurement residual:    $$v(k+1) = z(k+1) – z(k+1|k)$$</mathinline>

    <mathinline>5. Measurement residual covariance:    $$S(k+1) = H(k+1)*P(k+1|k)*H’(k+1) + R(k+1)$$</mathinline> 

    <mathinline>6. Kalman gain:    $$K(k+1) = P(k+1|k)*H’(k+1) / S(k+1)$$</mathinline>

    <mathinline>7. Updated state estimate:    $$x(k+1|k+1) = x(k+1|k) + K(k+1)*v(k+1)$$</mathinline>

    <mathinline>8. Updated state covariance:    $$P(k+1|k+1) = P(k+1|k) – K(k+1)*S(k+1)*K’(k+1)$$</mathinline>

    <p style="color:white;font-size:22px;"><b>Mahalanobis Distance</b></p>
    <mathinline>A distance from a track to a new measurement $$z(k+1)$$ that takes into account the measurement error covariance is the 
    Mahalanobis distance. To compute it, execute the first 5 filter equations to compute the measurement residual and its covariance. 
    Then the distance d is calculated from</mathinline>

    <mathblock>d^2 = \\frac{v’(k+1)}{S(k+1)}*v(k+1)</mathblock>

    <mathinline>$$v(k+1)$$, the measurement residual, is the difference between the measurement of the predicted track at time $$k+1$$ and 
    the new measurement at time $$k+1$$; and $$S(k+1)$$ is an estimate of the measurement covariance of the track at time $$k+1$$. $$S$$ 
    is the multi-dimensional generalization of standard deviation squared, so $$d$$ is basically the distance from the predicted track end-point 
    to the new measurement, in units of standard deviation.</mathinline>

    <h3>Algorithm Implementation</h3>
    <mathinline>We follow the general sequence of steps for multi-point target tracking using the Global Nearest Neighbor method for measure-to-track 
    assignment. Each track is represented by a track state consisting of a Kalman filter state, kal_state, and other implementation-dependent 
    track state variables:</mathinline>

    <p>struct &nbsp;kal_state &nbsp;  {float &nbsp;x_k_k; float &nbsp;p_k_k}</p>
    <p>struct &nbsp;track_state &nbsp; { kal_state &nbsp;filter_state; bool &nbsp;track_confirmed; bool &nbsp;hit_history[Nd]; float &nbsp;score_history[Nc];}</p>

    <mathinline>x_k_k denotes $$x(k|k)$$, p_k_k denotes $$P(k|k)$$, hit_history[n] is true at time $$k$$ if at time $$k-n$$ a measurement was 
    assigned to the track, and score_history[n] at time $$k$$ is the contribution to the track score from the measurement assigned to the 
    track at time $$k-n$$. The set of all tracks is a struct array of track states.</mathinline>

    <p>The sequence of steps in the algorithm is:</p> 

    <p>1. Get new measurement set.</p>

    <mathinline>2. Create cost matrix $$C$$ with rows corresponding to tracks and columns corresponding to measurements.</mathinline>

    <mathinline>3. Compute $$C_{i,j}$$ as the Mahalanobis distance of track $$i$$ to measurement $$j$$.</mathinline>

    <mathinline>4. Determine a set of measurement to track assignments that minimizes the total cost, with the constraint that a measurement 
    can be assigned to at most 1 track, and that a track can be assigned at most 1 measurement. The cost of not assigning a measurement 
    to any track is the gating threshold on the Mahalanobis distance. By extending the rows and columns of the cost matrix with the gating 
    threshold, the Munkres algorithm can be used for this step.</mathinline>

    <mathinline>5. For all measurement to track assignments: iterate the Kalman filter for the track with the assigned measurement.</mathinline>

    <mathinline>6. For all unassigned tracks: predict the track state and state covariance with filter equations (1) and (2), and update the 
    state and state covariance by setting them equal to their predicted values.</mathinline> 

    <mathinline>7. For all unassigned detections: initialize a new track state and add the new track to the struct array of track states.</mathinline>

    <mathinline>8. Prune and confirm tracks:</mathinline> 
    
    <mathinline>$$\\quad$$- To prune tracks: if a track is not assigned any measurement $$T_d$$ times in the last $$N_d$$ tracker updates, then the 
    track is deleted.</mathinline>
    <mathinline>$$\\quad$$- To confirm tracks: if a track is assigned at least $$T_c$$ measurements in the last $$N_c$$ tracker updates, then the track 
    is confirmed.</mathinline>

    <h3>Example: Correlative Interferometer Direction Finding</h3>
    <p>We consider the problem of Direction Finding (DF) with a Correlative Interferometer (CI). We simulated a scenario in which two wideband
     (BW 30 MHz) non-faded sources at azimuth arrival angles of 12 degrees and 53 degrees are received by a 9-element CI in -6 dB per-antenna
      SNR. A typical output spectrum of the interferometer is shown in the figure:</p>
    <img src="/images/Multi-target_Tracking/correlation.png"><br>

    <p>The spectrum shows many peaks, including the expected peaks at 12 degrees and 53 degrees. A scan step for the tracker corresponds 
    to one signal capture of a fixed duration from which a CI spectrum is derived; and the measurements of the scan step are defined to be 
    the angles of the up-to 2 highest correlation peaks that pass a threshold value of 0.3. So at each scan step there are 0,1, or 2 
    measurements.</p>

    <p>For comparative purposes, we ran a Single Target Tracker running a linear Kalman filter whose inputs are angle estimates of the 
    interferometer, where the single angle measurement at each scan step is the angle that corresponds to the highest peak of the CI spectrum.
     The figure shows the track following the dominant source at 12 degrees, but often the measurement input is due to the source at 53 
     degrees, and the track corresponding to 12 degrees becomes distorted by these inputs:</p>
    <img src="/images/Multi-target_Tracking/single_target_tracking.png"><br>

    <p>The next figure displays both the confirmed tracks and the tracks that were spawned but later deleted, for the multi-target tracker 
    run on the same 100 capture buffer inputs as in the single target tracker run. Notice that the two sources at 12 and 53 degrees lead to 
    confirmed tracks, while spurious detections caused 7 short-lived tracks that were not confirmed.</p>
    <img src="/images/Multi-target_Tracking/multi-target_tracking.png">

    <h3>About the Author</h3>

    <div style="height:300px; padding:30px">
        <img src="/images/tdoa/RobertAvatar.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:-10px'>Robert G. Link</p>
        <p>Principal Research Scientist for the Wireless Security Group at Bluvec Technologies Inc. He obtained his PhD at the University of British 
        Columbia in the Department of Physics. He works on signal processing algorithms for wireless communication systems, with recent emphasis on 
        detection and tracking system design and implementation.</p>
    </div>
    `
    },
    {
        "title": "Distance-based Keypoint Identification",
        "coverImage": "/images/DbKI/figure4-2.jpg",
        "intro": "article.dbki.intro",
        "order": 3,
        "content":
            `
    <p>In this article, we introduce a deep learning based approach for automatically selecting the nearest or farthest point in a given image. 
    This is not a difficult task for humans: given a photo, we can easily estimate the relative distance of objects using stereo vision. 
    Furthermore, we are adept at analyzing visual context. Intuition and common sense also enable us to understand complex imagery with ease. 
    For example, when we look at the image below, we immediately know that the black lamppost is close to the camera, whereas the buildings are farther away. 
    </p>

    <div style="text-align: center;">
        <img src="/images/DbKI/figure1.png">
        <em>Figure 1: image from COCO dataset (http://cocodataset.org/#explore?id=246625)</em>
    </div>

    <br/>
    <p>These skills are much more challenging for a computer to learn. Therefore, to simplify the task of finding the nearest or farthest point, 
    we break down the problem into smaller, more feasible steps. We first utilize a depth estimation model to obtain the local depth information of a given 
    image. Next, we apply a semantic segmentation model to focus on the relevant image region. Finally, we select the nearest or the farthest point based 
    on the depth information inside the region of interest.
    </p>

    <p>How would a computer estimate the distance of objects? In the next section, we first describe how this can be achieved using traditional computer 
    vision. Next, we present our deep-learning based approach for solving this problem.
    </p>

    <h3>Depth Estimation: The traditional approach</h3>
    <p>Traditional object depth estimation requires two cameras. It uses a trigonometric method to estimate the distance between the target object 
    and the cameras. Let's say we have two cameras placed a known distance apart from each other. If we also know the angles between the cameras and 
    the target object, we can calculate the object distance. We use the diagram below to better illustrate the case:
    </p>

    <div style="text-align: center; width: 30%;display: block;
    margin-left: auto; margin-right: auto;">
        <img src="/images/DbKI/figure2.png">
    </div>
    <div style="text-align: center"><em>Figure 2: triangle formed by the two cameras, C1 and C2, and the target object O</em></div>

    <br/>
    <mathinline>$$C_1$$ and $$C_2$$ represent the two cameras, and $$x$$ is the distance between them. Let $$y$$ be the distance between the target object and the camera $$C_1$$, 
    and $$z$$ be the distance between the target object and the camera $$C_2$$. By using the Law of Sines, we can calculate $$y$$ using the following equation:
    </mathinline>

    <mathblock>\\frac{y}{\\sin{(A_2)}}=\\frac{x}{\\sin{(180-A_1-A_2)}}</mathblock>

    <mathinline>Similarly, $$z$$ can be calculated as follows:</mathinline>

    <mathblock>\\frac{z}{\\sin{(A_1)}}=\\frac{x}{\\sin{(180-A_1-A_2)}}</mathblock>

    <mathinline>As we already know the distance $$x$$, once we find out the angles $$A_1$$ and $$A_2$$, we can then calculate the distances $$y$$ and $$z$$. 
    To get the angle $$A_1$$, we have to first study the camera parameters. In our case, the camera has 3840 x 2160 resolution, and it covers a 30-degree 
    horizontal view angle. If the object is at the center of the image captured by $$C_1$$, it is exactly in front of the camera $$C_1$$, and therefore the angle 
    $$A_3$$ is $$0^°$$. If the object is at the rightmost boundary of $$C_1$$’s captured image, the angle $$A_3$$ is $$15^°$$ ($$30^° / 2$$). We can use the following equation 
    to calculate $$A_3$$, i.e. the angle between the camera and the object.</mathinline>

    <mathblock>A_3 = \\tan^{-1}(\\frac{l\\tan{(\\theta_{C_1}}/2)}{p/2})</mathblock>

    <mathinline>where $$l$$ is the horizontal pixel distance between the target object and the image center point, $$\\theta_{C_1}$$ is the maximum 
    horizontal view angle of the camera (in our case it is $$30^°$$), and $$p$$ is the image width in pixels (in our case it is 3840). Lastly, 
    we can calculate $$A_1$$, which equals $$90^° - A_3$$.</mathinline>

    <mathinline>Similarly, we can use this method to calculate $$A_2$$. Finally, we can use the formula mentioned above to find $$y$$ and $$z$$.</mathinline>

    <p>Despite the effectiveness of this approach, it has a few key requirements that can be challenging to meet:<br/>
    1. We need two cameras.<br/>
    2. We need to know the exact distance between the two cameras.<br/>
    3. We need to keep the target object stationary. Alternatively, for a moving target, we need to capture images from two cameras at the same time.<br/>
    4. We need to locate the target object in both captured images.<br/>
    If we cannot achieve the above, this method is not viable, or the estimated distance might have a large margin of error.<br/>
    <br/>
    An alternative is to use deep neural networks, which would allow us to circumvent the restrictions described above. Single-camera depth estimation 
    is a well-known topic in deep learning-based computer vision research. Instead of finding the absolute distance, deep neural networks can estimate 
    the relative depth information given an image. Because the distance is relative, we do not need to rely on information such as camera position or focal 
    length. Since we are trying to find the nearest or farthest point within a single image, we do not need to obtain the absolute distance. Therefore, 
    this approach is perfect for our application.
    </p>

    <h3>Depth estimation using deep learning</h3>

    <p>There are many existing deep learning models for single-camera depth estimation. When working with limited time and data, pre-trained models 
    like these can be highly effective as a starting point. After some experimentation, We found that the AdaBins model has good performance in both indoor 
    and outdoor environments. Therefore, we decided to use AdaBins in the first stage of our pipeline. The model outputs a depth map, which estimates 
    objects’ distance relative to the camera.</p>
    
    <h3>More about AdaBins</h3>

    <p>Machine learning tasks usually fall into two categories: regression or classification. Regression refers to predicting numerical values 
    (e.g. predicting next month’s ticket sales), and classification refers to predicting categorical labels (e.g. predicting if a photo contains a cat 
        or a dog). Depth estimation is commonly achieved via regression. However, it is also possible to frame this as a classification problem.</p>
    
    <p>The motivation is to reduce the difficulty of the depth estimation task. To further illustrate this idea, let’s think about regression as an 
    infinite-class classification problem. Intuitively, the larger the number of target classes, the harder the classification problem is. Consider 
    the simplest classification task: binary classification. During training, the binary classifier tries to find a hyperplane in order to separate 
    the two classes into two subspaces, while minimizing the loss function. The best hyperplane is the optimal boundary between the two classes. When 
    we have a 3-class classification problem, we can formulate it as two binary classification sub-problems. E.g. if we have classes “A”, “B” and “C”, 
    we can use one sub-classifier to distinguish between “A” and “not A”. Then, we use another classifier to separate “not A” into “B” and “C”. Each 
    sub-classifier has its own hyperplane to optimize. When there are a lot of classes, the model needs to learn many complex decision boundaries, in 
    order to accommodate every single one of these classes. This is why the difficulty is reduced by replacing the regression task (an infinite-class 
        classification) with a fixed n-class classification task, as this reduces the complexity of the decision boundary.</p>
    
    <p>The authors of AdaBins propose a dynamic method to compute a depth map based on adaptive bins. Instead of predicting depth values via regression, 
    they classify each pixel into depth bins. Binning can cause discretization of depth values, leading to lower quality estimates. To address this problem,
     they also propose using linear combinations of the bin centers to calculate depth. During training, the AdaBins model learns two components: 
     1. the width of each bin, and 2. the range attention map. The bin width tells us where each bin begins and ends, while the range attention map 
     provides the weighting of each bin when calculating the depth. </p>

    <div style="text-align: center; width: 50%;display: block;
     margin-left: auto; margin-right: auto;">
        <img src="/images/DbKI/figure3.png">
    </div>
    <div style="text-align: center"><em>Figure 3: illustrates different binning strategies. AdaBins dynamically adjusts the bin widths for different input images. 
    Vertical lines indicate the boundaries between bins.</em></div><br/>

    <p>For more details, we refer readers to the original paper "AdaBins: Depth Estimation using Adaptive Bins"<a href='#ref'>[1]</a>.</p>

    <p>The AdaBins model generates a depth map for the input image. Figure 4(b) shows the output of the model. Objects farther away will appear 
    brighter, while nearer objects appear darker.</p>

    <div style="display: flex; flex-flow: row nowarp">
     <div style="width: 50%;text-align:center">
        <img src="/images/DbKI/figure4-1.jpg">
        <em>Figure 4: (a) the original image</em>
     </div >
     <div style="width: 50%;text-align:center">
        <img src="/images/DbKI/figure4-2.jpg">
        <em>Figure 4: (b) the depth map generated by the AdaBins model</em>
    </div>
    </div><br/>

    <p>As shown in the image above, we can see that the generated depth map provides relative distance estimates that match our intuition to a 
    certain degree. However, the result is not 100% correct. For example, the upper part of the tallest building is darker, which means that the 
    model incorrectly predicted that the upper part of the building is closer to the camera. Despite these imperfections, the model generally provides 
    reasonable relative depth estimates and they are suitable for our down-stream task. By checking pixel intensities, we can easily select the nearest 
    or farthest points, as estimated by the depth model.</p>

    <h3>Further refinement using regions of interest</h3>
    <p>In our experiments, we found that the farthest point selected by the depth model is usually in the sky. This is as expected, since 
    objects in the foreground are closer to the camera compared to the sky background. However, in some cases, we are not interested in the sky region. 
    To address this, we added a filter to prevent the sky background from being selected.</p>
    
    <p>Semantic segmentation<a href='#ref'>[2]</a> is very suitable for this use case. Segmentation groups together pixels that belong to
     the same object class. Thanks to the rapid development of autonomous driving, there are a lot of existing models for more fine-grained semantic 
     segmentation tasks, such as segmenting humans, vehicles, trees, etc. In our case, we only define two object classes: foreground and background. 
     The background is simply the sky region, while the foreground is the rest of the image. We use the pre-trained Cascade Segmentation Module proposed 
     in the paper “Scene Parsing through ADE20K Dataset”<a href='#ref'>[3]</a>. As shown in Figure 5, the output of our segmentation model is a binary map, which indicates 
     whether each pixel is foreground or background. </p>

     <div style="text-align: center; width: 50%;display: block;
     margin-left: auto; margin-right: auto;">
        <img src="/images/DbKI/figure5.jpg">
    </div>
    <div style="text-align: center"><em>Figure 5: the binary map generated by the semantic segmentation model.</em></div><br/>
    
    <p>By combining this segmentation map with the depth map obtained previously, we can then select the nearest or farthest point using the 
    following equations, where i and j correspond to the horizontal and vertical positions of any pixel:</p>
    <div style="text-align: center; width: 50%;display: block;
     margin-left: auto; margin-right: auto;">
        <img src="/images/DbKI/figure5-1.png">
    </div><br/>
    <p>The image below shows the experimental result from our pipeline. The red dot is the nearest point selected by the algorithm, while 
    the blue dot is the farthest point.</p>

    <div style="text-align: center; width: 50%;display: block;
     margin-left: auto; margin-right: auto;">
        <img src="/images/DbKI/figure6.jpg">
    </div>
    <div style="text-align: center"><em>Figure 6: the blue dot and the red dot show the farthest and nearest point selected by our pipeline respectively.</em></div>

    <h3 id="ref">Recap</h3>
    <p>To conclude, we described our single-camera approach for finding the nearest or farthest point in an image.We use two deep neural networks to 
    achieve this goal. The first model predicts the relative depth of objects in an image. The second model performs semantic segmentation and identifies 
    the region of interest. By combining these two models, we can select the nearest or the farthest point inside our target region.</p>

    <h3>References</h3>
    <p>[1] Bhat, Shariq Farooq, Ibraheem Alhashim, and Peter Wonka. "Adabins: Depth estimation using adaptive bins." 
    Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition. 2021.</p>
    <p>[2] Thoma, Martin. "A survey of semantic segmentation." arXiv preprint arXiv:1602.06541 (2016).</p>
    <p>[3] Zhou, Bolei, et al. "Scene parsing through ADE20k dataset." Proceedings of the IEEE conference on computer vision and pattern recognition. 2017.
    </p>
    <h3>About the Author</h3>

    <div style="height:300px; padding:30px">
        <img src="/images/post-estimate/avatar.jpeg" style='float:left; width:200px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:30px'>Sam Kwun-Ping, Lai</p>
        <p>Machine learning engineer at Bluvec Technologies Inc. He obtained his PhD at The Chinese University of Hong Kong. 
        He is passionate about natural language processing (NLP), computer vision, and transfer learning.</p>
    </div>

    <div style="height:300px; padding:30px">
        <img src="/images/DbKI/avatar.jpeg" style='float:left; width:200px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:30px'>Tina He</p>
        <p>Machine learning engineer at Bluvec Technologies Inc. She obtained her Bachelors degree at the University of Toronto, majoring in Machine Intelligence. 
        She enjoys working at the intersection of computer vision and deep learning.</p>
    </div>
    `
    },
    {
        "title": "Understanding GPS",
        "coverImage": "/images/GPS/GPS-cover.jpeg",
        "intro": "article.gps.intro",
        "order": 1,
        "new": true,
        "content": `

    <h3>Introduction</h3>
    <p>
        The Global Positioning System (GPS) is a constellation of 31 satellites that transmit radio
        signals from medium earth orbit which is developed and operated by the United States of America.
        Its basic service provides users with approximately 7 meters accuracy, 95 percent of the time,
        anywhere on or near the surface of the earth. To accomplish this, a user employs trilateration
        on the signals of at least four satellites. To trilaterate, the receiver needs to measure the
        reception time of a satellite signal, and it needs to determine the time at which that signal
        was transmitted, as well as the location of the satellite at that time of transmission.
        A GPS satellite carries an atomic clock that provides extremely accurate time, and
        this information is placed in the code broadcast by the satellite, enabling the receiver to
        continuously determine the time that the signal was broadcast. The code also contains data that
        a receiver uses, along with the transmission time, to continuously compute the location of the
        broadcasting satellite.
    </p>
    <p>
        Many articles explain how to trilaterate, given that the locations at the transmission times of
        the satellites used in the trilateration are known. This article focuses on filling that gap, and 
        explains how a GPS receiver can determine the satellite locations and transmission times required for the trilateration.
    </p>

    <h3>Legacy GPS</h3>

    <p>
        The interface between the GPS Space Segment and the GPS User Segment includes two RF links:
    </p>

    <ul>
        <li>L1 at frequency 1575.42 MHz</li>
        <li>L2 at frequency 1227.60 MHz</li>
    </ul>

    <p>
        Both signals are generated from a base frequency of 10.23 MHz.
    </p>

    <p>
        Legacy Navigation (LNAV) data is spread with the Coarse Acquisition code (C/A-code) and
        with the Precision code (P-code). When the anti-spoofing (AS) mode of operation is activated,
        the P-code is encrypted and called the Y-code. P(Y) is used to denote the code which may or
        may not be encrypted. The chipping rates and lengths of these codes are:
    </p>

    <div style="overflow-y:auto">
    <table>
        <tr style="font-family:'Mont-Bold'">
            <th>Code</th>
            <th>Chipping Rate</th>
            <th>Period (chips)</th>
            <th>Period (time)</th>
        </tr>
        <tr>
            <td>C/A</td>
            <td>1.023 Mcps</td>
            <td>1023</td>
            <td>1 ms</td>
        </tr>
        <tr>
            <td>P(Y)</td>
            <td>10.23 Mcps</td>
            <td>6.187104 * 10<sup>12</sup></td>
            <td>1 week</td>
        </tr>
    </table>
    </div>

    <p>
        The chips BPSK modulate the quadrature components of the carriers according to:
    </p>

    <ul>
        <li>L1 I-component carries the LNAV data spread by the P(Y) code</li>
        <li>L1 Q-component carries the LNAV data spread by the C/A code</li>
        <li>L2 I-component is configured to carry either the LNAV data spread by the P(Y) code, or the P(Y) code without
            data, or the LNAV data spread by the C/A code</li>
        <li>L2 Q-component is empty</li>
    </ul>

    <p>
        For Block IIR-M, IIF, and subsequent blocks of satellites, two additional ranging codes are
        transmitted. They are the L2 Civil-Moderate (L2 CM) code and the L2 Civil-Long (L2 CL) code;
        which are transmitted on the Q-component of the L2 link, and are used to spread the Civil
        Navigation (CNAV) data.
    </p>

    <p>
        The LNAV data is transmitted at 50 bps in 1500 bit-length frames of duration 30 seconds.
        Each frame consists of five subframes each of 6 seconds duration consisting of 300 bits. Each
        subframe is made up of 10 words with 30 bits per word. A word consists of 24 bits followed by
        6 parity bits.
    </p>

    <p>
    <div style="text-align: center;">
        <img src="/images/GPS/20210915-211556.png">
        <figcaption><em>LNAV Frame Structure</em></figcaption>
    </div>
    </p>

    <p>
        GPS time is expressed as a week number and a Time-Of-Week (TOW) count. The zero time-point is
        defined to be the same zero time-point as for Coordinated Universal Time (UTC), which is
        midnight January 5, 1980. GPS time differs from UTC because GPS is a continuous time scale,
        while UTC is corrected periodically with an integer number of leap seconds.
    </p>

    <p>
        The P-code for SV ID number i is the sum of two codes denoted X1 and X2i. The X1 code is
        15345000 chips in length which corresponds to exactly 1.5 seconds. The X1 code epoch defines
        the start of the 1.5 second intervals, and the TOW count increments by 1 at each X1 code epoch.
        The TOW is a modulo 403200 count and its rollover points define the start of a week.
    </p>

    <p>
        In each subframe, word 1 is the Telemetry (TLM) word and word 2 is the Handover Word (HOW).
        The TLM format is:
    </p>

    <p>
    <div style="text-align: center;">
        <img src="/images/GPS/20210915-220543.png">
        <figcaption><em>Telemetry Word Format</em></figcaption>
    </div>
    </p>

    <p>
        And the HOW format is:
    </p>

    <p>
    <div style="text-align: center;">
        <img src="/images/GPS/20210915-220711.png">
        <figcaption><em>Handover Word Format</em></figcaption>
    </div>
    </p>

    <p>
        The truncated TOW count indicates the 17 most significant bits of the full 19-bit TOW count,
        and this truncated count increments at 6 second intervals, which correspond to the subframe
        intervals. The HOW TOW gives the GPS time of when the first bit of the next subframe will be
        transmitted.
    </p>

    <p>
        Words 3 to 10 of the subframes carry three types of information:
    </p>

    <ul>
        <li>
            subframe 1 carries time-related and satellite status information
        </li>

        <li>
            subframes 2 and 3 carry orbital information of the transmitting satellite which enables
            one to determine the position of the satellite. This orbital information is called the
            ephemeris.
        </li>

        <li>
            subframes 4 and 5 contain 1 page of a 25 page series. Because transmitting each frame
            takes 30 seconds, transmitting the entire series spanned over 25 frames takes 12.5
            minutes. These pages contain the almanac, which consists of low-resolution ephemeris
            data for all the satellites in the GPS constellation, as well as the Navigation Message
            Correction Table, Ionospheric and UTC data, SV health data, and the almanac reference
            time and reference week number.
        </li>

    </ul>

    <p>
        The subframe 1 parameters are summarised in the following table:
    </p>

    <div style="overflow-y:auto">
    <table>
        <tr style="font-family:'Mont-Bold'">
            <th>Parameter</th>
            <th>No. of bits</th>
            <th>Scale Factor</th>
            <th>Units</th>
            <th>Description</th>
        </tr>
        <tr>
            <td>L2_C</td>
            <td>2</td>
            <td></td>
            <td></td>
            <td>indicates code on L2</td>
        </tr>
        <tr>
            <td>WN</td>
            <td>10</td>
            <td></td>
            <td>week</td>
            <td>week number modulo 1024</td>
        </tr>
        <tr>
            <td>L2P_DF</td>
            <td>1</td>
            <td></td>
            <td></td>
            <td>indicates if data on P code</td>
        </tr>
        <tr>
            <td>URA</td>
            <td>4</td>
            <td></td>
            <td></td>
            <td>user range accuracy index</td>
        </tr>
        <tr>
            <td>SVH</td>
            <td>6</td>
            <td></td>
            <td></td>
            <td>SV Health</td>
        </tr>
        <tr>
            <td>T_GD</td>
            <td>8</td>
            <td>2<sup>-31</sup></td>
            <td>seconds</td>
            <td>group delay differential</td>
        </tr>
        <tr>
            <td>IODC</td>
            <td>10</td>
            <td></td>
            <td></td>
            <td>issue of data and clock</td>
        </tr>
        <tr>
            <td>t_oc</td>
            <td>16</td>
            <td>2<sup>4</sup></td>
            <td>seconds</td>
            <td>clock data reference time</td>
        </tr>
        <tr>
            <td>a_f0</td>
            <td>22</td>
            <td>2<sup>-31</sup></td>
            <td>seconds</td>
            <td>zeroth order clock correction coefficient</td>
        </tr>
        <tr>
            <td>a_f1</td>
            <td>16</td>
            <td>2<sup>-43</sup></td>
            <td>sec/sec</td>
            <td>first order clock correction coefficient</td>
        </tr>
        <tr>
            <td>a_f2</td>
            <td>8</td>
            <td>2<sup>-55</sup></td>
            <td>1/sec</td>
            <td>second order clock correction coefficient</td>
        </tr>
    </table>
    </div>

    <p>
        The subframe 2,3 parameters are summarised in the following table:
    </p>

    <div style="overflow-y:auto">
    <table>
        <tr style="font-family:'Mont-Bold'">
            <th>Parameter</th>
            <th>No. of bits</th>
            <th>Scale Factor</th>
            <th>Units</th>
            <th>Description</th>
        </tr>
        <tr>
            <td>M_0</td>
            <td>32</td>
            <td>2<sup>-31</sup></td>
            <td>semi-circles</td>
            <td>mean anomaly at reference time</td>
        </tr>
        <tr>
            <td>delta_n</td>
            <td>16</td>
            <td>2<sup>-43</sup></td>
            <td>semi-circles/sec</td>
            <td>mean motion difference from computed value</td>
        </tr>
        <tr>
            <td>e</td>
            <td>32</td>
            <td>2<sup>-33</sup></td>
            <td>dimensionless</td>
            <td>ellipse eccentricity</td>
        </tr>
        <tr>
            <td>sqrt_A</td>
            <td>32</td>
            <td>2<sup>-19</sup></td>
            <td>sqrt(meters)</td>
            <td>square root of the semi-major axis</td>
        </tr>
        <tr>
            <td>Omega_0</td>
            <td>32</td>
            <td>2<sup>-31</sup></td>
            <td>semi-circles</td>
            <td>longitude of ascending node of orbit plane at weekly epoch</td>
        </tr>
        <tr>
            <td>i_0</td>
            <td>32</td>
            <td>2<sup>-31</sup></td>
            <td>semi-circles</td>
            <td>inclination angle at reference time</td>
        </tr>
        <tr>
            <td>omega</td>
            <td>32</td>
            <td>2<sup>-31</sup></td>
            <td>semi-circles</td>
            <td>argument of perigee</td>
        </tr>
        <tr>
            <td>dot_Omega</td>
            <td>24</td>
            <td>2<sup>-43</sup></td>
            <td>semi-circles/sec</td>
            <td>rate of right ascension</td>
        </tr>
        <tr>
            <td>dot_i</td>
            <td>14</td>
            <td>2<sup>-43</sup></td>
            <td>semi-circles/sec</td>
            <td>rate of inclination angle</td>
        </tr>
        <tr>
            <td>C_uc</td>
            <td>16</td>
            <td>2<sup>-29</sup></td>
            <td>radians</td>
            <td>cosine harmonic correction term to argument of latitude</td>
        </tr>
        <tr>
            <td>C_us</td>
            <td>16</td>
            <td>2<sup>-29</sup></td>
            <td>radians</td>
            <td>sine harmonic correction term to argument of latitude</td>
        </tr>
        <tr>
            <td>C_rc</td>
            <td>16</td>
            <td>2<sup>-5</sup></td>
            <td>meters</td>
            <td>cosine harmonic correction term to orbit radius</td>
        </tr>
        <tr>
            <td>C_rs</td>
            <td>16</td>
            <td>2<sup>-5</sup></td>
            <td>meters</td>
            <td>sine harmonic correction term to orbit radius</td>
        </tr>
        <tr>
            <td>C_ic</td>
            <td>16</td>
            <td>2<sup>-29</sup></td>
            <td>radians</td>
            <td>cosine harmonic correction term to angle of inclination</td>
        </tr>
        <tr>
            <td>C_is</td>
            <td>16</td>
            <td>2<sup>-29</sup></td>
            <td>radians</td>
            <td>sine harmonic correction term to angle of inclination</td>
        </tr>
        <tr>
            <td>t_oe</td>
            <td>16</td>
            <td>2<sup>4</sup></td>
            <td>seconds</td>
            <td>reference time of ephemeris</td>
        </tr>
        <tr>
            <td>IODE</td>
            <td>8</td>
            <td></td>
            <td></td>
            <td>Issue of Data (Ephemeris)</td>
        </tr>
    </table>
    </div>

    <p>
        A summary of the various data contained in each page of subframes 4,5 is:
    </p>

    <div style="overflow-y:auto">
    <table>
        <tr style="font-family:'Mont-Bold'">
            <th>Subframe</th>
            <th>Pages</th>
            <th>Data</th>
        </tr>
        <tr>
            <td>4</td>
            <td>1,6,11,12,14,15,16,19,20,21,22,23,24</td>
            <td>reserved</td>
        </tr>
        <tr>
            <td>4</td>
            <td>2,3,4,5,7,8,9,10</td>
            <td>almanac data for SV 25 thru 32</td>
        </tr>
        <tr>
            <td>4</td>
            <td>13</td>
            <td>NMCT</td>
        </tr>
        <tr>
            <td>4</td>
            <td>17</td>
            <td>special messages</td>
        </tr>
        <tr>
            <td>4</td>
            <td>18</td>
            <td>ionospheric and UTC data</td>
        </tr>
        <tr>
            <td>4</td>
            <td>25</td>
            <td>A-S flags/SV configurations for 32 SVs, plus health for SV 25 thru 32</td>
        </tr>
        <tr>
            <td>5</td>
            <td>1 thru 24</td>
            <td>almanac data for SV 1 thru 24</td>
        </tr>
        <tr>
            <td>5</td>
            <td>25</td>
            <td>SV health data for SV 1 thru 24, almanac reference time, almanac reference week number</td>
        </tr>
    </table>
    </div>

    <p>
        The almanac parameters for a single SV are given by:
    </p>

    <div style="overflow-y:auto">
    <table>
        <tr style="font-family:'Mont-Bold'">
            <th>Parameter</th>
            <th>No. of bits</th>
            <th>Scale Factor</th>
            <th>Units</th>
            <th>Description</th>
        </tr>
        <tr>
            <td>e</td>
            <td>16</td>
            <td>2<sup>-21</sup></td>
            <td>dimensionless</td>
            <td>ellipse eccentricity</td>
        </tr>
        <tr>
            <td>t_oa</td>
            <td>8</td>
            <td>2<sup>12</sup></td>
            <td>seconds</td>
            <td>almanac reference time</td>
        </tr>
        <tr>
            <td>d_i</td>
            <td>16</td>
            <td>2<sup>-19</sup></td>
            <td>semi-circles</td>
            <td>inclination angle relative to i_0 = 0.30</td>
        </tr>
        <tr>
            <td>dot_Omega</td>
            <td>16</td>
            <td>2<sup>-38</sup></td>
            <td>semi-circles/sec</td>
            <td>rate of right ascension</td>
        </tr>
        <tr>
            <td>sqrt_A</td>
            <td>24</td>
            <td>2<sup>-11</sup></td>
            <td>sqrt(meters)</td>
            <td>square root of the semi-major axis</td>
        </tr>
        <tr>
            <td>Omega_0</td>
            <td>24</td>
            <td>2<sup>-23</sup></td>
            <td>semi-circles</td>
            <td>longitude of ascending node of orbit plane at weekly epoch</td>
        </tr>
        <tr>
            <td>omega</td>
            <td>24</td>
            <td>2<sup>-23</sup></td>
            <td>semi-circles</td>
            <td>argument of perigee</td>
        </tr>
        <tr>
            <td>M_0</td>
            <td>24</td>
            <td>2<sup>-23</sup></td>
            <td>semi-circles</td>
            <td>mean anomaly at reference time</td>
        </tr>
        <tr>
            <td>a_f0</td>
            <td>11</td>
            <td>2<sup>-20</sup></td>
            <td>seconds</td>
            <td>zeroth order clock correction coefficient</td>
        </tr>
        <tr>
            <td>a_f1</td>
            <td>11</td>
            <td>2<sup>-38</sup></td>
            <td>sec/sec</td>
            <td>first order clock correction coefficient</td>
        </tr>
    </table>
    </div>

    <p>
        The almanac parameters are a subset of the ephemeris parameters, and are of lower resolution.
        Hence the equations for calculating the satellite position are moderately simpler, but the
        same conceptually. So we provide the computational algorithm here, and refer the reader to the
        GPS specification for the more complex and accurate calculation based on the ephemeris.
    </p>

    <h3>GPS Reception</h3>

    <p>
        The first step of a GPS receiver is to synchronise to the C/A ranging code. This will
        presumably be done by cross-correlating the received signal with a local version of the
        C/A code. The receiver must have a local notion of time which is not required to be
        synchronised to any particular system. The cross-correlation will be done at multiple
        time offsets until an offset is found that results in a sufficiently strong correlation peak.
        At this point a tracking loop can be started that maintains synchronization with the GPS signal.
    </p>

    <p>
        The signal is phase equalised, the Q quadrature is BPSK demodulated, and the preamble of the
        TLM is searched for to achieve alignment with the subframe boundaries. Upon subframe alignment,
        the HOW is read and the TOW field provides t_sv, which is the SV PRN code phase time at message
        transmission time. This time is precisely the time of the start of the first chip of the
        subframe immediately following the subframe that contained the HOW. The receiver shall also
        record the local message reception time, t_rx, of reception of the first chip of the subframe.
        The user requires the message transmission time, message reception time, and the satellite
        position at the message transmission time for at least 4 satellites to determine the user
        position at message reception time.
    </p>

    <p>
        Corrections to t_sv are made to account for satellite clock bias, drift and ageing to obtain
        the GPS time at message transmission, t, by application of the first order polynomial:
    </p>

    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">t = t_sv - dt_sv</div>
        <span>(1)</span>
    </div>
    </p>

    <p>
        with correction term
    </p>

    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">dt_sv = a_f0 + a_f1*t_k</div>
        <span>(2)</span>
    </div>
    </p>

    <p>
        where t_k is the time difference from the reference time
    </p>

    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">t_k = t - t_oa</div>
        <span>(3)</span>
    </div>
    </p>

    <p>
        In this last equation t can be approximated by t_sv. The GPS control segment shall update the
        almanac often enough to ensure that GPS time, t, shall differ from the almanac reference time,
        t_oa, by less than 3.5 days. To account for TOW roll-overs, if t_k is greater than 302,400
        seconds, subtract 604,800 seconds from t; if t_k is less than -302,400 seconds, add 604,800
        seconds to t_k.
    </p>

    <p>
        With the transmission time t computed, the earth-fixed cartesian coordinates of a satellite
        are obtained by the series of equations:
    </p>

    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            mu = 3.986005*10<sup>14</sup> m<sup>3</sup>/sec<sup>2</sup>
        </div>
        <div style="display:inline-block;width:350px">
            earth's gravitational constant
        </div>
        <span>(4)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            dot_Omega_e = 7.2921151467*10<sup>-5</sup> rad/sec
        </div>
        <div style="display:inline-block;width:350px">
            earth's rotation rate
        </div>
        <span>(5)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            A = sqrt_A<sup>2</sup>
        </div>
        <div style="display:inline-block;width:350px">
            semi-major orbital axis
        </div>
        <span>(6)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            n_0 = sqrt(mu/A<sup>3</sup>)
        </div>
        <div style="display:inline-block;width:350px">
            computed mean motion (rad/sec)
        </div>
        <span>(7)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            M_k = M_0 + n_0*t_k
        </div>
        <div style="display:inline-block;width:350px">
            mean anomaly
        </div>
        <span>(8)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            M_k = E_k - e*sin(E_k)
        </div>
        <div style="display:inline-block;width:350px">
            E_k is eccentric anomaly (radians)
        </div>
        <span>(9)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            nu_k = arctan((sqrt(1 - e<sup>2</sup>)*sin(E_k))/(cos(E_k) - e))
        </div>
        <div style="display:inline-block;width:350px">
            true anomaly
        </div>
        <span>(10)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            Phi_k = nu_k + omega
        </div>
        <div style="display:inline-block;width:350px">
            argument of latitude
        </div>
        <span>(11)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            r_k = A*(1 - e*cos(E_k))
        </div>
        <div style="display:inline-block;width:350px">
            corrected radius
        </div>
        <span>(12)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            i_k = 0.30 + d_i
        </div>
        <div style="display:inline-block;width:350px">
            inclination (semi-circles)
        </div>
        <span>(13)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            x'_k = r_k*cos(Phi_k), y'_k = r_k*sin(Phi_k)
        </div>
        <div style="display:inline-block;width:350px">
            positions in orbital plane
        </div>
        <span>(14)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            Omega_k = Omega_0 + (dot_Omega - dot_Omega_e)*t_k - dot_Omega_e*t_oa
        </div>
        <div style="display:inline-block;width:350px">
            longitude of ascending node
        </div>
        <span>(15)</span>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            x_k = x'_k*cos(Omega_k) - y'_k*cos(i_k)*sin(Omega_k)
        </div>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            y_k = x'_k*sin(Omega_k) + y'_k*cos(i_k)*cos(Omega_k)
        </div>
    </div>
    </p>
    <p>
    <div style="margin-left:60px">
        <div style="display:inline-block;width:460px">
            z_k = y'_k*sin(i_k)
        </div>
        <div style="display:inline-block;width:350px">
            earth-fixed coordinates
        </div>
        <span>(16)</span>
    </div>
    </p>

    <p>
        Equation (9) can be solved by an iterative technique. 
    </p>

    <p>
    The more accurate calculation based on the ephemeris data also accounts for 
    propagation delays or decreases in the signal's speed caused by the ionosphere by 
    utilising the time delay differential between L1 and L2. Systems that have only 
    the L1 or the L2 link must instead perform an additional computation based on the broadcasted ionospheric data.
    </p>

    <h3>Conclusion</h3>

    <p>
        The receiver uses the time difference between the time of signal reception and the broadcast
        time to compute the range from the receiver to a satellite used in the trilateration.
        With the ranges to the satellites and the cartesian coordinates of the
        satellites when the signal was sent, the receiver can compute its own three-dimensional position.
    </p>

    <h3>About the Author</h3>

    <div style="height:300px; padding:30px">
        <img src="/images/tdoa/RobertAvatar.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:-10px'>Robert G. Link</p>
        <p>Principal Research Scientist for the Wireless Security Group at Bluvec Technologies Inc. He obtained his PhD at the University of British 
        Columbia in the Department of Physics. He works on signal processing algorithms for wireless communication systems, with recent emphasis on 
        detection and tracking system design and implementation.</p>
    </div>
        `

    },
    {
        "title": "C-UAS RF Sensors Part I",
        "coverImage": "/images/rfsensor/rfsensor1-cover.jpg",
        "intro": "article.rfsensor.intro1",
        "order": 1,
        "new": true,
        "content": `
        <p>Radio Frequency (RF) sensors are a type of technology used in Counter-Unmanned Aerial System (C-UAS) systems to detect, 
        identify, and track unmanned aerial vehicles (UAVs), also known as drones. RF sensors work by detecting the radio signals 
        emitted by drones, including GPS, Wi-Fi, video, and cellular signals.</p>

        <h3>C-UAS RF workflow:</h3> 
        
        <p>Once the RF signal from a drone is detected, the C-UAS system can use the information to identify, geolocate, and track 
        the drone and even sometimes the GC (Ground Controller) pilot. Depending on the type of C-UAS system, the RF sensor data 
        may trigger an action, such as a soft or hard kill like jamming the drone's signals with a jammer, a spoofing navigation 
        system with spoofing, or some other types of hard kill.</p>

        <img src="/images/rfsensor/rf-sensor1.png" />
        
        <h3>Different types of RF sensors:</h3>
        
        <p>Several types of RF sensors can be used in C-UAS systems, including:</p>
        
        <ul>
        <li>Directional with Direction Finding (DF) Sensors: These sensors determine the direction of the drone's radio 
        signal and can be used to determine the location of the drone.</li>
        
        <li>Solid antenna 360 coverage: these types of RF sensors have solid 360 antenna not flat segmented antennas. </li>
        
        <li>Radio Detection and Ranging (Radar) Sensors: These sensors use RF signals to detect the presence and position of 
        objects in the air, including drones. </li>
        </ul>
        
        <h3>What is the difference between an RF and a Radar?</h3> 
        
        <p>RF stands for "Radio Frequency" and refers to the frequency range used for communication purposes in electronics. 
        On the other hand, RADAR stands for "Radio Detection and Ranging" and is a technology used for detecting and locating objects 
        by transmitting radio waves and analyzing the reflected signals. In short, RF is a frequency range, while RADAR is a technology 
        that uses radio waves to detect and locate objects.</p>
        
        <h3>Is Radar better then RF? </h3>
        
        <p>It is not a matter of "better" or "worse" as they serve different purposes and have different strengths and weaknesses. </p>
        
        <p>RF is better suited for communication purposes such as wireless communication, broadcasting, and data transmission, 
        while RADAR is better for detecting and locating objects, measuring speed and range, and mapping terrain. </p>
        
        <p>In some cases, a combination of both RF and RADAR may be used to achieve a desired result. The choice of which technology to use 
        depends on the specific application and requirements. </p>
        
        <p>Overall, RF sensors are an important component of C-UAS systems and can play a critical role in detecting and tracking drones. 
        However, they also have their cons and pros which we will cover in our next articles. </p>

        <h3>About the Author</h3>

        <div style="height:300px; padding:30px">
            <img src="/images/avatar_daniel.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
            <p style='margin-top:-10px'>Daniel Oskooei</p>
            <p>With over 20 years of experience in telecommunication, IT, IoT, and technology industries, Daniel brought a new strategic approach to the defense industry. His expertise has resulted in increased customer satisfaction and faster turnover.</p>
        </div>
        `
    },
    {
        "title": "C-UAS RF Sensors Part II: Pros and Cons ",
        "coverImage": "/images/rfsensor/rfsensor2-cover.jpg",
        "intro": "article.rfsensor.intro2",
        "order": 1,
        "new": true,
        "content":
            `
        <h3>Pros of C-UAS RF sensors: </h3>
        
        <ul>
        <li>Versatility: C-UAS RF sensors can detect and track a wide range of UAVs (Unmanned Aerial Vehicles) that operate in the RF spectrum. </li>
        
        <li>Real-time Detection: C-UAS RF sensors can provide real-time detection of UAVs, allowing for quick response times. </li>
        
        <li>Portability: C-UAS RF sensors are often compact and portable, making them easy to deploy in various locations. </li>
        
        <li>Cost-effective: Compared to other UAV detection systems, C-UAS RF sensors are often more affordable and cost-effective. </li>
        </ul>
        
        <h3>Cons of C-UAS RF sensors: </h3>
        
        <ul>
        <li>LOS Line of Sight: One of RF sensors’ limitations is LOS preference. It is preferred to have a clear line of sight for better detection of UAV RF.</li> 
        
        <li>Interference and noise level: Interference from other sources such as other RF signals can affect the accuracy and reliability of C-UAS RF sensors. </li>
        
        <li>UAV RF transmission power: RF sensors are detecting UAV RF. The more powerful the UAV and GC RF the higher their detection range of them. For the same reason, DJI drones are easier to be detected than non-DJI. </li>
        
        <li>False Alarms: C-UAS RF sensors can sometimes generate false alarms due to interference from non-UAV sources. </li>
        
        <li>Technical Expertise: Operating and maintaining C-UAS RF sensors often requires technical expertise, which may limit their use by non-specialist personnel.</li> 
        
        <li>Non-RF UAVs that are pre-programmed or navigation-based cannot be detected. </li>
        </ul>

        <h3>How Bluvec Technologies resolved these disadvantages of C-UAS RF sensors </h3>
        
        <p>Bluvec DSI (Deep Signal Inspection):</p>  
        
        <p>Bluvec DSI (Deep Signal Inspection) technology is a type of software-defined radio (SDR) technology designed for radio frequency (RF) threat detection and analysis. It is used to detect and identify various RF signals, including those from unmanned aerial vehicles (UAVs), cell phones, and other devices operating in the RF spectrum. </p>
        
        <p>The technology works by analyzing signals at a deep level, beyond just their frequency and modulation characteristics, to detect anomalies that may indicate a threat. This allows it to detect new or previously unknown threats and distinguish them from benign signals. 
        
        <h3>Some key features and benefits of Bluvec DSI technology include: </h3>
        
        <ul>
        <li>Wideband Coverage: Bluvec DSI technology can cover a wide frequency range, allowing it to detect a wide range of RF signals.</li>  
        
        <li>Real-time Detection: Bluvec DSI technology provides real-time detection and analysis of RF signals, allowing for quick response times.</li>  
        
        <li>Advanced Threat Detection: Bluvec DSI technology uses advanced signal analysis techniques to detect a wide range of threats, including those from UAVs, cell phones, and other RF devices. 
        
        <li>Integration with Other Systems: Bluvec DSI technology can be integrated with other security systems, such as video cameras, to provide a comprehensive security solution. 
        
        <li>User-friendly Interface: Bluvec DSI technology is designed with a user-friendly interface, allowing for easy operation and analysis of detected signals. 
        
        <li>Using the world’s largest library of drones for more detailed identification. </li> 
        
        <li>Zero percent of false alarms: Almost zero percent of fall alarms.</li> 

        <img src="/images/rfsensor/rf-sensor2.png"/>
        </ul>
        
        <h3>Bluvec Technologies DSI GEN2 (Deep Signal Inspection Generation 2)</h3>
        
        <p>It is an updated version of Bluvec's DSI technology as 2nd generation for radio frequency (RF) threat detection and analysis. The technology is designed to provide improved performance, accuracy, and versatility compared to the previous generation. </p>
        
        <p>Some key features and benefits of Bluvec Technologies DSI GEN2 technology include: </p>
        
        <ul>
        <li>Improved Detection Accuracy: Bluvec Technologies DSI GEN2 technology uses advanced signal analysis techniques to provide improved accuracy in detecting RF threats, including those from unmanned aerial vehicles (UAVs), cell phones, and other RF devices. </li>
        
        <li>Resolving preferred LOS: Bluvec Technologies DSI GEN2 technology is designed to almost work with or without LOS. More so, when compared to other competitors’ RF sensors this technology tackles the LOS aggressively and its performance is times better than competitors.</li> 
        
        <li>Advanced Signal Processing: Bluvec Technologies DSI GEN2 technology uses advanced signal processing techniques to provide improved performance and real-time analysis of detected signals. </li>
        
        <li>Interference and noise level: Operating in a civilian area with huge RF noise interference makes it impossible for normal RF sensors to detect or identify the UAVs. With aid of Bluvec Technologies DSI GEN2 Bluvec RF sensors are functioning at full capacity even in the middle of dense cities with no failure. </li>
        
        <li>Detail identification: Provide detailed identification such as drone brand, model, speed, direction, altitude, and serial number printed on the drone. </li>
        
        <li>Geolocation: Geolocation of DJI and Non-DJI drones and GC (Ground Control) pilot with single unit of sensor. </li>
        </ul>
        
        <p>Overall, Bluvec Technologies DSI GEN2 technology is designed to provide improved RF threat detection and analysis capabilities, and identification making it a useful tool for security and defense applications.</p> 
        
        <img src="/images/rfsensor/rf-sensor3.png" width="700" style="margin-bottom:100px"/>

        <h3>About the Author</h3>

        <div style="height:300px; padding:30px">
            <img src="/images/avatar_daniel.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
            <p style='margin-top:-10px'>Daniel Oskooei</p>
            <p>With over 20 years of experience in telecommunication, IT, IoT, and technology industries, Daniel brought a new strategic approach to the defense industry. His expertise has resulted in increased customer satisfaction and faster turnover.</p>
        </div>
        `
    },
    {
        "title": "Launch Of ADS-B Feature on Bluvec Technologies RF Sensors",
        "coverImage": "/images/rfsensor/ADSB1.png",
        "intro": "article.ADSB.intro",
        "order": 0,
        "new": true,
        "content":
            `

        <img src="/images/rfsensor/ADSB.png" width="700" style="margin-bottom:100px"/>
        <p>
        Bluvec Technologies has recently launched an exciting new feature to the RF Blusensor. The  ADS-B-based detection and tracking system has been officially added to Blusensors’ features. The new feature, which is now available for customers, offers enhanced capabilities for detecting and surveillance of aircraft, making Blusensor an even more powerful tool for airport security and air traffic management such as Unmanned Aircraft System Traffic Management UTM.  
        </p>
        
        <h3>Automatic Dependent Surveillance-Broadcast (ADS-B) </h3>


        <p>
        ADS-B is an essential technology used in aviation for real-time communication of aircraft position, speed, and altitude information. It is a key component of air traffic management systems, allowing for safer and more efficient use of airspace. 

        </br></br>ADS-B works by using GPS technology to determine the aircraft's position and then broadcasting that information to other aircraft and ground stations. This allows pilots and air traffic controllers to see the position and trajectory of all aircraft in the airspace in real time, improving situational awareness and reducing the risk of collisions. 

        </br></br>ADS-B has many advantages over traditional radar systems, including higher accuracy, greater coverage, and lower maintenance costs.

        </br></br>ADS-B technology is not only used in manned aircraft but is also becoming increasingly important in the detection and tracking of unmanned aircraft systems (UAS), commonly known as drones.  

        </br></br>This new feature is a significant enhancement to the capabilities of Blusensor, and it provides a valuable tool for airports and other facilities more specifically Smart Cities’ aerospace traffic management including UTM. This feature will make Blusensors even more subtle toward situational awareness and cities aerospace UAV and manned aircraft management. 

        </br></br>This breakthrough can be a significant selling point for Bluvec Technologies by targeting small and medium size commercial airports. It provides an extra feature to emphasize to potential customers the advantages of using Blusensor over other C-UAS solutions. The system's enhanced capabilities demonstrate Bluvec Technologies' commitment to providing its customers with the most advanced and effective C-UAS solutions. 
        
        </p>

        <h3>About the Author</h3>

        <div style="height:300px; padding:30px">
            <img src="/images/avatar_daniel.jpeg" style='float:left; width:120px; border-radius:50%; margin-right:30px'>
            <p style='margin-top:-10px'>Daniel Oskooei</p>
            <p>With over 20 years of experience in telecommunication, IT, IoT, and technology industries, Daniel brought a new strategic approach to the defense industry. His expertise has resulted in increased customer satisfaction and faster turnover.</p>
        </div>
        `
    },
    {
        "title": "AI: A second layer to detect drones in Correctional Facilities",
        "coverImage": "/images/ai_correctional_cover.jpg",
        "intro": "article.aiCorrectional.intro",
        "order": -4,
        "new": true,
        "content": `
            <p>In the ever-evolving landscape of security threats, correctional facilities face a persistent challenge - drone smuggling. RF and Non-RF drones silently infiltrate prison walls, carrying contraband that poses serious risks to safety and order within the institutions. To counter this increasingly menace, Artificial Intelligence technology is introduced by Bluvec as a second layer detection.</p>

            <p>As the world grapples with complex security challenges, AI has emerged as a transformative technology, reshaping conventional security paradigms. With its ability to process colossal data streams in real-time, recognize patterns, and adapt based on experiences, AI has become an indispensable tool in fortifying correctional facilities against drone threats.</p>

            <div style="text-align: center;padding:30px">
                <img src="/images/ai_correctional_video_new.gif">
            </div>

            <h3>Bluvec's Rapid Target Inspection: A revolutionary AI defense</h3>

            <p>In the vanguard of AI-powered drone detection, Bluvec presents Blucam, a Rapid Target Inspection (RTI), and unparalleled defense against the enigmatic world of stealthy drones. This sophisticated solution leaps beyond the limitations of traditional RF technology, capturing high-resolution 4k images of even the most diminutive targets with unrivaled precision.</p>

            <p>At the core of RTI lies Bluvec's advanced artificial neural network, finely honed to detect targets as minuscule as a few pixels in size. Powered by AI, the camera system adeptly differentiates between actual threats, such as drones, and benign objects like birds and airplanes, minimizing false alarms and ensuring an unerring focus on potential risks.</p>

            <p>Going beyond conventional solutions, Blucam is endowed with short-term memory, capturing temporal information about visual regions of interest. By continuously assessing frames in succession, the AI system refines its risk assessment capabilities, unearthing subtle yet significant nuances that might escape other security measures.</p>

            <p>As drone smuggling threatens the sanctity of correctional facilities, AI emerges as the guardian of safety, empowering institutions to face this audacious challenge head-on. Blucam stands as an impenetrable shield, providing real-time visuals on approaching threats and arming correctional facilities with the knowledge to respond proactively.</p>

            <div style="padding:30px 0;">
                <a style="color:var(--denim);font-size:24px;font-family:var(--font-family-mont-bold);" href="https://bluvec.com/Products/Blucam">Know more about Blucam here</a>
            </div>
        `,
    },
    {
        "title": "DIY Drones: Is your Correctional Facility ready to detect them",
        "coverImage": "/images/diy_correctional_cover.jpg",
        "intro": "article.diyCorrectional.intro",
        "order": -3,
        "new": true,
        "content": `
            <p>Over the years, drones became more accessible for end-users. The average cost of a drone in 2022 was $540. What is good from the recreational side is also unsafe for a few industries, such as correctional facilities.</p>

            <p>Based on <a href="https://dronesurveyservices.com/drone-statistics/">drone statistics</a>, the FAA has registered 855,860 drones only in the US as of 2023, and the drone market is expected to grow at an annual rate of 1.21% from 2023 to 2028.</p>

            <div style="text-align: center;padding: 30px;">
                <img width=800 src="/images/diy_correctional_image.jpg">
            </div>

            <p>In 2022, the <a href="https://www.statista.com/statistics/1234655/worldwide-consumer-drone-market-size">consumer drone market</a> size amounted to almost five billion U.S. dollars and, by 2027, this value is expected to reach roughly 8.74 billion U.S. dollars.</p>

            <p>The DJI is the dominant player in the drone market, with a market size of approximately 60% globally, according to industry statistics. However, DIY/Kit drones are becoming more popular for contraband introduction, especially in Correctional Facilities. Users can easily buy the drone parts separately on the internet and assemble the drone by themselves. With a C2 link (command and control link) the aircraft is remotely piloted by a human or is programmed to fly autonomously, which makes it more difficult to detect the pilot’s location and surveillance in that specific zone.</p>

            <p>By decoding the downlink C2 link it's possible to obtain the GPS coordinates of the drone at frequent intervals and therefore geolocate it very accurately.</p>

            <p>Just like any other drone, the DIY drones are required to be compliant with the new Remote ID regulations which mandate that drones continually transmit their GPS coordinates while in flight. However, these RID broadcasts are of very short range (1 to 2 km); whereas with Bluvec strategy of decoding the C2 link, can obtain the drone coordinates at much greater ranges – the same range as the drone’s maximum range from its controller, which is tens of kilometers for many models.</p>

            <div style="padding:30px 0;">
                <a style="color:var(--denim);font-size:24px;font-family:var(--font-family-mont-bold);" href="https://bluvec.com/Solutions/Correctional">Know more about Bluvec Solutions for Correctional Facilities</a>
            </div>
        `,
    },
    {
        "title": "The Correctional Facilities threats are in the sky and here is what you need to know",
        "coverImage": "/images/sky_correctional_cover.jpg",
        "intro": "article.skyCorrectional.intro",
        "order": -2,
        "new": true,
        "content": `

        <p>Correctional facilities constantly struggle to prevent the trafficking of contraband, which is becoming more common with the increasing use of drones.</p>

        <p>The US and Canada was listed as the top countries affected by contraband in prisons, followed by other countries in South America and Europe.</p>

        <p>Drones are efficient, and their operators can remain anonymous while delivering significant amounts of contraband directly to inmates. While small drones can carry a maximum of 2lbs, large drones have a maximum take-off weight of more than 55 lbs, and helping the delivery of drugs, weapons, alcohol and cellphones.</p>

        <div style="text-align: center;padding: 30px;">
            <img width=800 src="/images/sky_correctional_image1.jpg">
        </div>

        <div style="text-align: center;padding: 30px;">
            <img width=800 src="/images/sky_correctional_image2.jpg">
            <a href="https://www.cbc.ca/news/canada/british-columbia/drug-smuggling-drones-1.6822091">Image of drone carrying a package - CBC News Interview</a>
        </div>

        <p>Besides the contraband, drones flying with cameras can analyze the prison perimeter patrols, times, zones, and leading to the risk of delivering this information to the inmates.</p>

        <p>To counter this threat, advanced technology is necessary. RF and Vision solutions, such as the Blusensor and Blucam, are the preferred options for protecting correctional facilities, as they are passive, work under all weather conditions, operate 24/7, and offer installation flexibility based on the layout of the facility.</p>

        <div style="padding:30px 0;">
            <a style="color:var(--denim);font-size:24px;font-family:var(--font-family-mont-bold);" href="https://bluvec.com/Solutions/Correctional">Know more about Bluvec Solutions for Correctional Facilities</a>
        </div>
        `,
    },
    {
        "title": "What is Auto-focus",
        "coverImage": "/images/focuscover.jpg",
        "intro": "article.focus.intro",
        "order": -1,
        "new": true,
        "content":
            `
    <p>Most modern day cameras are equipped with auto-focus capabilities. This means that the camera can automatically adjust the position of its lens, until the subject is in focus.
    </p>

    <p>To the human eye, it is obvious when an image or even part of an image is out of focus, but how does a camera distinguish between a sharp and blurry subject? There are a number of computer vision techniques that aim to do this automatically. In the section below, I will introduce two of them: variance of Laplacian, and gradient-based metrics. Both techniques require the camera to capture a sequence of images at different focal lengths. By comparing image features, we can determine the optimal focal length to give us a sharp-looking image. The visualization below shows how the gradient map reflects whether the camera is in focus. 
    </p>

    <div style="text-align: center;">
        <img src="/images/focus.gif">
        <em>Figure 1: RGB image and gradient map captured at different camera focal lengths. When the image is in focus, a lot of fine detail is visible in the gradient map.</em>
    </div>

    <h3>Gradient-based metrics</h3>
    <p>Image gradient refers to the rate of change of pixel intensity. Gradient is directional, so a common strategy is to compute the gradient in horizontal and vertical directions and combine them. Large gradients are often associated with boundaries, shadows, and significant changes in colour. The image below shows how the gradient magnitude compares with the original RGB image. In the gradient visualization, white pixels correspond to the largest magnitude, and black pixels correspond to the lowest. In this case, the largest gradients occur at the edges, so we can see the drone’s outline quite clearly. On the other hand, the background is almost completely free of large gradients because it is uniformly coloured.
    </p>
    
    <br/>

    <div style="text-align: center; width: 30%;display: block;
    margin-left: auto; margin-right: auto;">
        <img src="/images/focus2.gif">
    </div>
    <div style="text-align: center"><em>Figure 2: Gradient magnitude for an image with sharp focus</em></div>
    <br/>
    <p>When the drone is out of focus, the resulting gradient map will appear blurry. The visualization below shows the outcome after we add some Gaussian blur to the original image. There is a noticeable reduction in the strength of the gradient map, because the sharp changes in pixel intensity have been smoothed out. By comparing the gradient values, we can deduce which image has more distinct edges and is therefore in better focus. 
    </p>
    <br/>
    <div style="text-align: center; width: 30%;display: block;
    margin-left: auto; margin-right: auto;">
        <img src="/images/focus1.gif">
    </div>
    <div style="text-align: center"><em>Figure 3: Gradient magnitude for an image with blurry focus. The gradient magnitude appears less well-defined compared to Figure 2.</em></div>

    <br/>
    <h3>Variance of Laplacian</h3>
    <p>Computing the image gradient uses first order derivatives. The Laplacian operator is a second order operator that can also be used for similar purposes. The diagram below compares the outputs from first and second order operators, when there is an edge in the image. In this example, we consider a simple case where a dark region transitions into a bright region. The vertical boundary between these two regions can be considered as an edge. The edge registers as a peak for the first order derivative, and as a zero-crossing (when the peak goes from positive to negative) for the second order derivative. Both methods will result in large magnitude values when there is an edge present. The variance of Laplacian is a convenient metric to sum up the entire image. If an image is in focus, its Laplacian values will occupy a wide range and produce a larger variance. 
    </p>
    
    <div style="text-align: center; width: 30%;display: block;
    margin-left: auto; margin-right: auto;">
        <img src="/images/gragh_focus.png">
    </div>
    <div style="text-align: center"><em>Figure 4: Visualization of first vs. second order operators’ response to an edge.<br/> Top image is the input. Middle image is the first order response. Bottom image is the second order response.</em></div>

    
    <h3>What are some challenges for auto-focus?</h3>
    <h3>I.Noise</h3>
    <p>When there is significant camera noise, the image can appear very grainy and speckled. The variance of Laplacian is quite sensitive to noise, so it can mistake speckles for actual objects in the image. Smoothing the image beforehand can help to reduce the effect of noise.
    </p>
    <h3>II.Moving objects</h3>
    <p>When there are notable changes in scenery, it becomes harder to compare a sequence of images. Ideally, objects in the image should be mostly static. If we take a sequence of images containing moving traffic, trees on a windy day, etc., we will see variation in gradient/ Laplacian even without changing the camera focus. Again, smoothing can help to a certain extent, but mostly static scenery will yield the best outcome.</p>
    <h3>III.Low texture regions</h3>
    <p>How do we know if the camera is in focus if it is looking at a white wall? Neither Laplacian nor gradient metrics would be reliable in this case. Since both methods rely on the presence of edge-based image features, a nearly uniform region will not contain useful information.</p>
    <h3>IV.Changes in illumination</h3>
    <p>Even if the scene is entirely static, illumination differences can skew the results. The same scene at different times of the day (e.g. just after dusk vs. sunny afternoon) will appear very different. Both metrics introduced in this article rely on pixel level changes. When there is poor visibility or low lighting, the foreground/background contrast is less pronounced, thus leading to lower values.</p>
    <h3>Other relevant applications</h3>
    <p>While gradient/ Laplacian metrics can serve as a way to evaluate image focus, they can also be helpful for other tasks, such as: comparing whether 2 photos were taken at the same scene, assessing whether an image is mostly blank or empty, separating foreground from background, etc. Edge maps can also be used for higher level deep learning based computer vision tasks, such as object segmentation, scene analysis, and image inpainting (i.e. using computer-generated pixels to fill corrupt/ missing regions in an image).</p>
    <h3>About the Author</h3>

    
    <div style="height:300px; padding:30px">
        <img src="/images/DbKI/avatar.jpeg" style='float:left; width:200px; border-radius:50%; margin-right:30px'>
        <p style='margin-top:30px'>Tina He</p>
        <p>Machine learning engineer at Bluvec Technologies Inc. She obtained her Bachelors degree at the University of Toronto, majoring in Machine Intelligence. 
        She enjoys working at the intersection of computer vision and deep learning.</p>
    </div>
    `
    },
];

articles.sort((a, b) => a.order - b.order);

export default articles;
